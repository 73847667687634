import { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { getAuthenticatedUser } from "./rbac";

/**
 * A hook to access the authenticated user from our database
 * @returns {Object} - Object containing database user data and loading state
 */
export const useDbUser = () => {
  const {
    user: auth0User,
    isAuthenticated,
    isLoading: isAuth0Loading,
    getAccessTokenSilently,
  } = useAuth0();
  const [dbUser, setDbUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      if (!isAuthenticated || !auth0User) {
        setIsLoading(false);
        return;
      }

      try {
        setIsLoading(true);

        // Get access token from Auth0
        const accessToken = await getAccessTokenSilently();

        // Pass the token to getAuthenticatedUser to fetch user from database
        const userData = await getAuthenticatedUser(auth0User, accessToken);

        setDbUser(userData);
        setError(null);
      } catch (err) {
        console.error("Error fetching authenticated user from database:", err);
        setError(err.message || "Failed to fetch user data from database");
      } finally {
        setIsLoading(false);
      }
    };

    if (!isAuth0Loading) {
      fetchUser();
    }
  }, [auth0User, isAuthenticated, isAuth0Loading, getAccessTokenSilently]);

  /**
   * Check if the user has a specific role in our database
   * @param {String} role - The role to check for
   * @returns {Boolean} - Whether the user has the role
   */
  const hasRole = (role) => {
    return dbUser && dbUser.role === role;
  };

  /**
   * Check if the user is an admin in our database
   * @returns {Boolean} - Whether the user is an admin in the database
   */
  const isDbAdmin = () => {
    return hasRole("admin");
  };

  // Combined loading state from internal loading and Auth0 loading
  const combinedLoadingState = isLoading || isAuth0Loading;

  return {
    // User data
    dbUser,

    // Loading states - providing both for compatibility
    isLoading: combinedLoadingState,
    isDbUserLoading: combinedLoadingState,

    // Error information
    error,

    // Role checking methods
    hasRole,
    isDbAdmin,
  };
};

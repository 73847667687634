import React, { useState, useEffect, useCallback } from "react";
import { Alert } from "reactstrap";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { getConfig } from "../config";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Snackbar from "@mui/material/Snackbar";
import CircleIcon from "@mui/icons-material/Circle";
import RefreshIcon from "@mui/icons-material/Refresh";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "@fontsource/roboto-mono";
import "./DevConsole.css";
import { codeStyles, colors } from "../theme";

export const DevConsoleComponent = () => {
  const { audience } = getConfig();
  const apiOrigin =
    process.env.NODE_ENV === "production"
      ? "https://www.gazz.io"
      : "http://localhost:8080";

  const [state, setState] = useState({
    error: null,
    accessToken: "",
  });

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [apiStatus, setApiStatus] = useState({
    isChecking: true,
    isConnected: false,
    lastChecked: null,
  });
  const [mongoDbStatus, setMongoDbStatus] = useState({
    isChecking: true,
    isConnected: false,
    status: "unknown",
    lastChecked: null,
    details: null,
    error: null,
  });

  const { getAccessTokenSilently, loginWithPopup, getAccessTokenWithPopup } =
    useAuth0();

  // Memoize fetchAccessToken using useCallback
  const fetchAccessToken = useCallback(async () => {
    try {
      const token = await getAccessTokenSilently();
      setState((prevState) => ({
        ...prevState,
        accessToken: token,
      }));
      return token;
    } catch (error) {
      console.error("Error fetching access token", error);
      return null;
    }
  }, [getAccessTokenSilently]); // Dependency array

  // Call fetchAccessToken when the component mounts
  useEffect(() => {
    fetchAccessToken();
  }, [fetchAccessToken]);

  // Check API status on component mount
  useEffect(() => {
    const checkApiStatus = async () => {
      try {
        setApiStatus((prev) => ({ ...prev, isChecking: true }));
        const token = await fetchAccessToken();
        if (!token) {
          setApiStatus({
            isChecking: false,
            isConnected: false,
            lastChecked: new Date(),
          });
          return;
        }

        const response = await fetch(`${apiOrigin}/api/external`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            timestamp: new Date().toISOString(),
            message: "API Status Check",
          }),
        });

        setApiStatus({
          isChecking: false,
          isConnected: response.ok,
          lastChecked: new Date(),
        });
      } catch (error) {
        setApiStatus({
          isChecking: false,
          isConnected: false,
          lastChecked: new Date(),
        });
        console.error("API status check failed:", error);
      }
    };

    checkApiStatus();
  }, [apiOrigin, fetchAccessToken]);

  // Check MongoDB status on component mount
  useEffect(() => {
    const checkMongoDbStatus = async () => {
      try {
        setMongoDbStatus((prev) => ({ ...prev, isChecking: true }));
        const token = await fetchAccessToken();
        if (!token) {
          setMongoDbStatus({
            isChecking: false,
            isConnected: false,
            status: "unknown",
            lastChecked: new Date(),
            details: null,
            error: "No access token available",
          });
          return;
        }

        const response = await fetch(`${apiOrigin}/api/mongodb-status`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            timestamp: new Date().toISOString(),
            message: "MongoDB Status Check",
          }),
        });

        const data = await response.json();

        setMongoDbStatus({
          isChecking: false,
          isConnected: data.connected,
          status: data.status,
          lastChecked: new Date(),
          details: {
            connectionInfo: data.connectionInfo,
            environment: data.environment,
            timestamp: data.timestamp,
          },
          error: null,
        });
      } catch (error) {
        setMongoDbStatus({
          isChecking: false,
          isConnected: false,
          status: "error",
          lastChecked: new Date(),
          details: null,
          error: error.message,
        });
        console.error("MongoDB status check failed:", error);
      }
    };

    checkMongoDbStatus();
  }, [apiOrigin, fetchAccessToken]);

  const handleConsent = async () => {
    try {
      await getAccessTokenWithPopup();
      setState((prevState) => ({
        ...prevState,
        error: null,
      }));
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        error: error.error,
      }));
    }
  };

  const handleLoginAgain = async () => {
    try {
      await loginWithPopup();
      setState((prevState) => ({
        ...prevState,
        error: null,
      }));
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        error: error.error,
      }));
    }
  };

  const handle = (e, fn) => {
    e.preventDefault();
    fn();
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(state.accessToken).then(
      () => {
        setSnackbarOpen(true);
      },
      (err) => {
        console.error("Failed to copy text: ", err);
      }
    );
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const refreshApiStatus = () => {
    setApiStatus((prev) => ({ ...prev, isChecking: true }));

    const checkApiStatus = async () => {
      try {
        const token = await fetchAccessToken();
        if (!token) {
          setApiStatus({
            isChecking: false,
            isConnected: false,
            lastChecked: new Date(),
          });
          return;
        }

        const response = await fetch(`${apiOrigin}/api/external`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            timestamp: new Date().toISOString(),
            message: "API Status Check",
          }),
        });

        setApiStatus({
          isChecking: false,
          isConnected: response.ok,
          lastChecked: new Date(),
        });
      } catch (error) {
        setApiStatus({
          isChecking: false,
          isConnected: false,
          lastChecked: new Date(),
        });
        console.error("API status check failed:", error);
      }
    };

    checkApiStatus();
  };

  const refreshMongoDbStatus = () => {
    setMongoDbStatus((prev) => ({ ...prev, isChecking: true }));

    const checkMongoDbStatus = async () => {
      try {
        const token = await fetchAccessToken();
        if (!token) {
          setMongoDbStatus({
            isChecking: false,
            isConnected: false,
            status: "unknown",
            lastChecked: new Date(),
            details: null,
            error: "No access token available",
          });
          return;
        }

        const response = await fetch(`${apiOrigin}/api/mongodb-status`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            timestamp: new Date().toISOString(),
            message: "MongoDB Status Check",
          }),
        });

        const data = await response.json();

        setMongoDbStatus({
          isChecking: false,
          isConnected: data.connected,
          status: data.status,
          lastChecked: new Date(),
          details: {
            connectionInfo: data.connectionInfo,
            environment: data.environment,
            timestamp: data.timestamp,
          },
          error: null,
        });
      } catch (error) {
        setMongoDbStatus({
          isChecking: false,
          isConnected: false,
          status: "error",
          lastChecked: new Date(),
          details: null,
          error: error.message,
        });
        console.error("MongoDB status check failed with error:", error);
      }
    };

    checkMongoDbStatus();
  };

  return (
    <>
      <Box className="mb-5" sx={{ maxWidth: 1200, mx: "auto", px: 2 }}>
        {/* Environment Banner */}
        <Card
          variant="outlined"
          className="api-status-container"
          sx={{ mb: 3 }}
        >
          <CardContent sx={{ p: 2, "&:last-child": { pb: 2 } }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography
                variant="h6"
                className="environment-label"
                sx={{ fontSize: "1.1rem", fontWeight: 600, mr: 2 }}
              >
                Environment
              </Typography>
              <code className="environment-value" style={codeStyles}>
                {process.env.NODE_ENV || "development"}
              </code>
            </Box>
          </CardContent>
        </Card>

        {/* API Status Indicator */}
        <Card
          variant="outlined"
          className="api-status-container"
          sx={{ mb: 3 }}
        >
          <CardContent sx={{ p: 2, "&:last-child": { pb: 2 } }}>
            <Box
              className="api-status-header"
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                pb: 1,
                mb: 1.5,
              }}
            >
              <Typography
                variant="h6"
                sx={{ fontSize: "1.1rem", fontWeight: 600 }}
              >
                API Status
              </Typography>
              <Tooltip title="Refresh status" placement="top">
                <IconButton
                  size="small"
                  onClick={refreshApiStatus}
                  disabled={apiStatus.isChecking}
                  color="primary"
                  sx={{
                    "&:focus": {
                      outline: "none",
                    },
                  }}
                >
                  <RefreshIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </Box>
            <div className="api-status-indicator">
              {apiStatus.isChecking ? (
                <div className="status-checking">
                  <CircleIcon
                    className="status-icon checking"
                    fontSize="small"
                  />
                  <span>Checking connection...</span>
                </div>
              ) : (
                <div
                  className={`status-result ${
                    apiStatus.isConnected ? "connected" : "disconnected"
                  }`}
                >
                  <CircleIcon
                    className={`status-icon ${
                      apiStatus.isConnected ? "connected" : "disconnected"
                    }`}
                    fontSize="small"
                  />
                  <span>
                    {apiStatus.isConnected
                      ? "Connected to API at "
                      : "Disconnected from API at "}
                    <code className="api-url" style={codeStyles}>
                      {apiOrigin}/api/external
                    </code>
                  </span>
                </div>
              )}
              {apiStatus.lastChecked && (
                <div className="status-timestamp">
                  Last checked: {apiStatus.lastChecked.toLocaleTimeString()}
                </div>
              )}
            </div>
          </CardContent>
        </Card>

        {/* MongoDB Status Indicator */}
        <Card
          variant="outlined"
          className="api-status-container"
          sx={{ mb: 3 }}
        >
          <CardContent sx={{ p: 2, "&:last-child": { pb: 2 } }}>
            <Box
              className="api-status-header"
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                pb: 1,
                mb: 1.5,
              }}
            >
              <Typography
                variant="h6"
                sx={{ fontSize: "1.1rem", fontWeight: 600 }}
              >
                MongoDB Status
              </Typography>
              <Tooltip title="Refresh status" placement="top">
                <IconButton
                  size="small"
                  onClick={refreshMongoDbStatus}
                  disabled={mongoDbStatus.isChecking}
                  color="primary"
                  sx={{
                    "&:focus": {
                      outline: "none",
                    },
                  }}
                >
                  <RefreshIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </Box>
            <div className="api-status-indicator">
              {mongoDbStatus.isChecking ? (
                <div className="status-checking">
                  <CircleIcon
                    className="status-icon checking"
                    fontSize="small"
                  />
                  <span>Checking MongoDB connection...</span>
                </div>
              ) : (
                <div
                  className={`status-result ${
                    mongoDbStatus.isConnected ? "connected" : "disconnected"
                  }`}
                >
                  <CircleIcon
                    className={`status-icon ${
                      mongoDbStatus.isConnected ? "connected" : "disconnected"
                    }`}
                    fontSize="small"
                  />
                  <span>
                    {mongoDbStatus.isConnected
                      ? `Connected to MongoDB (${mongoDbStatus.status})`
                      : `MongoDB ${mongoDbStatus.status}`}
                  </span>
                </div>
              )}
              {mongoDbStatus.lastChecked && (
                <div className="status-timestamp">
                  Last checked: {mongoDbStatus.lastChecked.toLocaleTimeString()}
                </div>
              )}
              {mongoDbStatus.details?.connectionInfo && (
                <div className="connection-details">
                  <div className="details-row">
                    <span className="detail-label">Host:</span>
                    <code style={codeStyles}>
                      {mongoDbStatus.details.connectionInfo.host}
                    </code>
                  </div>
                  {mongoDbStatus.details.connectionInfo.name && (
                    <div className="details-row">
                      <span className="detail-label">Database:</span>
                      <code style={codeStyles}>
                        {mongoDbStatus.details.connectionInfo.name}
                      </code>
                    </div>
                  )}
                  {mongoDbStatus.details.environment && (
                    <div className="details-row">
                      <span className="detail-label">Environment:</span>
                      <code style={codeStyles}>
                        {mongoDbStatus.details.environment.nodeEnv}
                      </code>
                      {mongoDbStatus.details.environment.herokuDyno !==
                        "not on heroku" && (
                        <span className="dyno-tag">
                          Heroku: {mongoDbStatus.details.environment.herokuDyno}
                        </span>
                      )}
                    </div>
                  )}
                </div>
              )}
              {mongoDbStatus.error && (
                <div className="error-message">
                  Error: {mongoDbStatus.error}
                </div>
              )}
            </div>
          </CardContent>
        </Card>

        {state.error === "consent_required" && (
          <Alert color="warning">
            You need to{" "}
            <a
              href="#/"
              class="alert-link"
              onClick={(e) => handle(e, handleConsent)}
            >
              consent to get access to this application's API
            </a>
          </Alert>
        )}

        {state.error === "login_required" && (
          <Alert color="warning">
            You need to{" "}
            <a
              href="#/"
              class="alert-link"
              onClick={(e) => handle(e, handleLoginAgain)}
            >
              log in again
            </a>
          </Alert>
        )}

        <Card
          variant="outlined"
          className="api-status-container"
          sx={{ mb: 3 }}
        >
          <CardContent sx={{ p: 2, "&:last-child": { pb: 2 } }}>
            <Box
              className="api-status-header"
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                pb: 1,
                mb: 1.5,
              }}
            >
              <Typography
                variant="h6"
                sx={{ fontSize: "1.1rem", fontWeight: 600 }}
              >
                Auth Token
              </Typography>
              <Tooltip title="Copy to clipboard" placement="top">
                <IconButton
                  size="small"
                  onClick={copyToClipboard}
                  disabled={!state.accessToken}
                  color="primary"
                  sx={{
                    "&:focus": {
                      outline: "none",
                    },
                  }}
                >
                  <ContentCopyIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </Box>
            <div className="api-status-indicator">
              {!state.accessToken ? (
                <div className="status-checking">
                  <CircleIcon
                    className="status-icon checking"
                    fontSize="small"
                  />
                  <span>Loading token...</span>
                </div>
              ) : (
                <>
                  <div className="status-result connected">
                    <CircleIcon
                      className="status-icon connected"
                      fontSize="small"
                    />
                    <span>Authentication token available</span>
                  </div>
                  <div className="connection-details">
                    <div className="details-row">
                      <span className="detail-label">Token:</span>
                      <code className="token-code-styled" style={codeStyles}>
                        {state.accessToken}
                      </code>
                    </div>
                  </div>
                </>
              )}
            </div>
          </CardContent>
        </Card>

        <Snackbar
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={handleSnackbarClose}
          message="Token copied to clipboard"
        />
      </Box>
    </>
  );
};

export default DevConsoleComponent;

import React, { useState } from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Switch from "@mui/material/Switch";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Grid from "@mui/material/Grid";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import NotificationsIcon from "@mui/icons-material/Notifications";
import SecurityIcon from "@mui/icons-material/Security";
import LanguageIcon from "@mui/icons-material/Language";
import VisibilityIcon from "@mui/icons-material/Visibility";
import HelpIcon from "@mui/icons-material/Help";
import { colors } from "../theme";

import Loading from "../components/Loading";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { useDbUser } from "../utils/useDbUser";

export const SettingsComponent = () => {
  const { dbUser, isLoading: isDbUserLoading } = useDbUser();
  const [settings, setSettings] = useState({
    darkMode: true,
    notifications: true,
    twoFactorAuth: false,
    language: "English",
    profileVisibility: "Public",
  });
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  if (isDbUserLoading) {
    return <Loading />;
  }

  const handleSwitchChange = (setting) => (event) => {
    setSettings({
      ...settings,
      [setting]: event.target.checked,
    });

    setSnackbar({
      open: true,
      message: `${setting} setting updated successfully!`,
      severity: "success",
    });
  };

  const handleButtonAction = (action) => () => {
    setSnackbar({
      open: true,
      message: `${action} action completed successfully!`,
      severity: "success",
    });
  };

  const handleCloseSnackbar = () => {
    setSnackbar({
      ...snackbar,
      open: false,
    });
  };

  return (
    <Container sx={{ mb: 5, mt: 3 }}>
      <Card
        elevation={2}
        sx={{
          mb: 4,
          backgroundColor: `${colors.primary}10`,
          borderRadius: 2,
        }}
      >
        <CardHeader
          title={
            <Typography variant="h4" component="h1" gutterBottom>
              Settings
            </Typography>
          }
          sx={{ pb: 0 }}
        />
        <CardContent>
          <Typography variant="h6" sx={{ mt: 2 }}>
            Customize Your Experience
          </Typography>
          <Typography variant="body1" paragraph>
            Configure your preferences and account settings.
          </Typography>
          <Divider sx={{ my: 2 }} />

          <Box
            sx={{
              bgcolor: `${colors.primary}15`,
              borderRadius: 2,
              p: 2,
              color: colors.text.primary,
            }}
          >
            <List sx={{ "& .MuiListItem-root": { py: 1.5 } }}>
              <ListItem>
                <ListItemIcon>
                  <DarkModeIcon sx={{ color: colors.primary }} />
                </ListItemIcon>
                <ListItemText
                  primary="Dark Mode"
                  secondary="Toggle between light and dark theme"
                  primaryTypographyProps={{ fontWeight: 500 }}
                />
                <Switch
                  checked={settings.darkMode}
                  onChange={handleSwitchChange("darkMode")}
                  color="primary"
                />
              </ListItem>

              <ListItem>
                <ListItemIcon>
                  <NotificationsIcon sx={{ color: colors.primary }} />
                </ListItemIcon>
                <ListItemText
                  primary="Notifications"
                  secondary="Enable push notifications"
                  primaryTypographyProps={{ fontWeight: 500 }}
                />
                <Switch
                  checked={settings.notifications}
                  onChange={handleSwitchChange("notifications")}
                  color="primary"
                />
              </ListItem>

              <ListItem>
                <ListItemIcon>
                  <SecurityIcon sx={{ color: colors.primary }} />
                </ListItemIcon>
                <ListItemText
                  primary="Two-Factor Authentication"
                  secondary="Add an extra layer of security to your account"
                  primaryTypographyProps={{ fontWeight: 500 }}
                />
                <Switch
                  checked={settings.twoFactorAuth}
                  onChange={handleSwitchChange("twoFactorAuth")}
                  color="primary"
                />
              </ListItem>
            </List>
          </Box>
        </CardContent>
      </Card>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Paper
            elevation={3}
            sx={{
              p: 2,
              backgroundColor: colors.paper,
              borderRadius: 2,
            }}
          >
            <Typography variant="h6" sx={{ mb: 2 }}>
              Language & Regional
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
              <LanguageIcon sx={{ mr: 2, color: colors.primary }} />
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant="body1">Language</Typography>
                <Typography variant="body2" color="text.secondary">
                  Current: {settings.language}
                </Typography>
              </Box>
              <Button
                variant="outlined"
                size="small"
                onClick={handleButtonAction("Language change")}
                sx={{
                  color: colors.text.primary,
                  borderColor: colors.primary,
                  "&:hover": {
                    borderColor: colors.secondary,
                  },
                }}
              >
                Change
              </Button>
            </Box>
            <Divider sx={{ my: 2 }} />
            <Button
              variant="contained"
              fullWidth
              onClick={handleButtonAction("Regional settings")}
              sx={{
                bgcolor: colors.primary,
                "&:hover": {
                  bgcolor: `${colors.primary}CC`,
                },
              }}
            >
              Update Regional Settings
            </Button>
          </Paper>
        </Grid>

        <Grid item xs={12} md={6}>
          <Paper
            elevation={3}
            sx={{
              p: 2,
              backgroundColor: colors.paper,
              borderRadius: 2,
            }}
          >
            <Typography variant="h6" sx={{ mb: 2 }}>
              Privacy Settings
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
              <VisibilityIcon sx={{ mr: 2, color: colors.primary }} />
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant="body1">Profile Visibility</Typography>
                <Typography variant="body2" color="text.secondary">
                  Current: {settings.profileVisibility}
                </Typography>
              </Box>
              <Button
                variant="outlined"
                size="small"
                onClick={handleButtonAction("Privacy update")}
                sx={{
                  color: colors.text.primary,
                  borderColor: colors.primary,
                  "&:hover": {
                    borderColor: colors.secondary,
                  },
                }}
              >
                Change
              </Button>
            </Box>
            <Divider sx={{ my: 2 }} />
            <Button
              variant="contained"
              fullWidth
              onClick={handleButtonAction("Privacy")}
              sx={{
                bgcolor: colors.primary,
                "&:hover": {
                  bgcolor: `${colors.primary}CC`,
                },
              }}
            >
              Advanced Privacy Settings
            </Button>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper
            elevation={3}
            sx={{
              p: 2,
              mt: 2,
              backgroundColor: colors.paper,
              borderRadius: 2,
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <HelpIcon sx={{ mr: 2, color: colors.primary }} />
              <Typography variant="h6">Help & Support</Typography>
            </Box>
            <Divider sx={{ my: 2 }} />
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <Button
                  variant="outlined"
                  fullWidth
                  onClick={handleButtonAction("Documentation")}
                  sx={{
                    color: colors.text.primary,
                    borderColor: colors.primary,
                    "&:hover": {
                      borderColor: colors.secondary,
                    },
                  }}
                >
                  Documentation
                </Button>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Button
                  variant="outlined"
                  fullWidth
                  onClick={handleButtonAction("FAQ")}
                  sx={{
                    color: colors.text.primary,
                    borderColor: colors.primary,
                    "&:hover": {
                      borderColor: colors.secondary,
                    },
                  }}
                >
                  FAQ
                </Button>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Button
                  variant="outlined"
                  fullWidth
                  onClick={handleButtonAction("Contact Support")}
                  sx={{
                    color: colors.text.primary,
                    borderColor: colors.primary,
                    "&:hover": {
                      borderColor: colors.secondary,
                    },
                  }}
                >
                  Contact Support
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default withAuthenticationRequired(SettingsComponent, {
  onRedirecting: () => <Loading />,
});

import React from "react";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "./Loading";

/**
 * A component that protects routes based only on authentication (no role checks)
 * @param {Object} props - Component props
 * @param {Function} props.component - Component to render if authenticated
 * @returns {JSX.Element} - Protected route component that only requires authentication
 */
export const AuthenticationRoute = ({ component, ...args }) => {
  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => <Loading />,
  });

  return <Component {...args} />;
};

export default AuthenticationRoute;

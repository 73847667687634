import React from "react";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { colors } from "../theme";

const Loading = () => (
  <Box
    className="spinner"
    sx={{
      position: "absolute",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      height: "50vh",
      width: "50vw",
      backgroundColor: colors.background,
    }}
  >
    <CircularProgress color="primary" size={60} thickness={4} />
  </Box>
);

export default Loading;

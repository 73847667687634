import React from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import { colors } from "../theme";

const TermsOfService = () => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        width: "100%",
        minHeight: "100vh",
        backgroundColor: colors.background,
        color: colors.text.primary,
        pt: 8,
        pb: 20,
      }}
    >
      <Container maxWidth="md">
        <Box sx={{ display: "flex", alignItems: "center", mb: 4 }}>
          <Button
            startIcon={<ArrowBackIcon />}
            onClick={() => navigate("/")}
            sx={{
              color: "rgba(255,255,255,0.7)",
              "&:hover": {
                color: "rgba(255,255,255,1)",
                backgroundColor: "rgba(255,255,255,0.1)",
              },
              mr: 2,
            }}
          >
            Back to Home
          </Button>
        </Box>

        <Typography variant="h2" component="h1" gutterBottom sx={{ mb: 4 }}>
          Terms of Service
        </Typography>

        <Typography
          variant="h5"
          component="h2"
          gutterBottom
          sx={{ mt: 4, mb: 2 }}
        >
          Last Updated: March 30, 2025
        </Typography>

        <Typography paragraph>
          Please read these Terms of Service carefully before using the Gazz.io
          website or any services offered by Gazz.io.
        </Typography>

        <Typography
          variant="h5"
          component="h2"
          gutterBottom
          sx={{ mt: 4, mb: 2 }}
        >
          1. Acceptance of Terms
        </Typography>

        <Typography paragraph>
          By accessing or using our services, you agree to be bound by these
          Terms and our Privacy Policy. If you do not agree to these Terms, you
          may not use our services.
        </Typography>

        <Typography
          variant="h5"
          component="h2"
          gutterBottom
          sx={{ mt: 4, mb: 2 }}
        >
          2. Changes to Terms
        </Typography>

        <Typography paragraph>
          We may modify these Terms at any time. If we make changes, we will
          notify you by revising the date at the top of these Terms and, in some
          cases, we may provide you with additional notice. Your continued use
          of our services after the changes have been made will constitute your
          acceptance of the changes.
        </Typography>

        <Typography
          variant="h5"
          component="h2"
          gutterBottom
          sx={{ mt: 4, mb: 2 }}
        >
          3. Account Registration
        </Typography>

        <Typography paragraph>
          In order to access certain features of our services, you may be
          required to register for an account. You must provide accurate and
          complete information and keep your account information updated.
        </Typography>

        <Typography paragraph>
          You are responsible for safeguarding your account password and for any
          activities or actions under your account. We cannot and will not be
          liable for any loss or damage arising from your failure to comply with
          the above requirements.
        </Typography>

        <Typography
          variant="h5"
          component="h2"
          gutterBottom
          sx={{ mt: 4, mb: 2 }}
        >
          4. User Conduct
        </Typography>

        <Typography paragraph>
          You are solely responsible for your conduct and any content that you
          submit, post, or display on or through our services. You agree that
          you will not violate any law, contract, intellectual property, or
          other third-party right or commit a tort.
        </Typography>

        <Typography paragraph>You also agree not to:</Typography>

        <ul>
          <Typography component="li" paragraph>
            Use our services in any manner that could interfere with, disrupt,
            negatively affect, or inhibit other users from fully enjoying our
            services
          </Typography>
          <Typography component="li" paragraph>
            Use our services in any way that could damage, disable, overburden,
            or impair the functioning of our services
          </Typography>
          <Typography component="li" paragraph>
            Attempt to circumvent any filtering, security measures, or access
            controls that we employ
          </Typography>
          <Typography component="li" paragraph>
            Use our services for any illegal or unauthorized purpose
          </Typography>
        </ul>

        <Typography
          variant="h5"
          component="h2"
          gutterBottom
          sx={{ mt: 4, mb: 2 }}
        >
          5. Termination
        </Typography>

        <Typography paragraph>
          We may terminate or suspend your access to all or part of our
          services, without notice, for conduct that we believe violates these
          Terms or is harmful to other users of our services, our business, or
          third parties, or for any other reason.
        </Typography>

        <Typography
          variant="h5"
          component="h2"
          gutterBottom
          sx={{ mt: 4, mb: 2 }}
        >
          6. Disclaimer of Warranties
        </Typography>

        <Typography paragraph>
          Our services are provided "as is" and "as available" without any
          warranty, either express or implied. We disclaim all warranties of any
          kind, whether express or implied, including but not limited to the
          implied warranties of merchantability, fitness for a particular
          purpose, and non-infringement.
        </Typography>

        <Typography
          variant="h5"
          component="h2"
          gutterBottom
          sx={{ mt: 4, mb: 2 }}
        >
          7. Limitation of Liability
        </Typography>

        <Typography paragraph>
          To the maximum extent permitted by law, we shall not be liable for any
          indirect, incidental, special, consequential, or punitive damages, or
          any loss of profits or revenues, whether incurred directly or
          indirectly, or any loss of data, use, goodwill, or other intangible
          losses, resulting from your access to or use of or inability to access
          or use our services.
        </Typography>

        <Typography
          variant="h5"
          component="h2"
          gutterBottom
          sx={{ mt: 4, mb: 2 }}
        >
          8. Governing Law
        </Typography>

        <Typography paragraph>
          These Terms shall be governed by the laws of the jurisdiction in which
          Gazz.io is registered, without regard to its conflict of law
          provisions.
        </Typography>

        <Typography
          variant="h5"
          component="h2"
          gutterBottom
          sx={{ mt: 4, mb: 2 }}
        >
          9. Contact Information
        </Typography>

        <Typography paragraph>
          If you have any questions about these Terms, please contact us at
          terms@gazz.io.
        </Typography>

        {/* Bottom spacer */}
        <Box sx={{ height: 100 }} />
      </Container>
    </Box>
  );
};

export default TermsOfService;

import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Container } from "reactstrap";
import Box from "@mui/material/Box";

import Loading from "./components/Loading";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import Home from "./views/Home";
import Profile from "./views/Profile";
import Settings from "./views/Settings";
import DevConsole from "./views/DevConsole";
import MongoDBViewer from "./views/MongoDBViewer";
import Dashboard from "./views/Dashboard";
import PrivacyPolicy from "./views/PrivacyPolicy";
import TermsOfService from "./views/TermsOfService";
import ProtectedRoute from "./components/ProtectedRoute";
import AuthenticationRoute from "./components/AuthenticationRoute";
import { useAuth0 } from "@auth0/auth0-react";
import { colors } from "./theme";
import { MixpanelProvider } from "./contexts/MixpanelContext";
import { trackSignIn } from "./utils/mixpanel";
import PageTracker from "./components/PageTracker";

// styles
import "./App.css";

/**
 * Check if a user is authorized based on MongoDB database role
 * @param {Object} auth0User - Auth0 user object
 * @param {Object} dbUser - Our database user object
 * @returns {Boolean} Whether the user is authorized
 */
const isMongoDBAdmin = (auth0User, dbUser) => {
  // Check if the user is an admin in our database
  return dbUser && dbUser.role === "admin";
};

const App = () => {
  const { isLoading, error, user, isAuthenticated } = useAuth0();

  // Track user when authenticated
  useEffect(() => {
    if (isAuthenticated && user) {
      trackSignIn(user);
    }
  }, [isAuthenticated, user]);

  if (error) {
    return (
      <Box
        sx={{ color: colors.text.primary, backgroundColor: colors.background }}
      >
        Oops... {error.message}
      </Box>
    );
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <MixpanelProvider>
      <Router>
        <PageTracker />
        <Routes>
          {/* Landing page - Coming Soon */}
          <Route path="/" element={<Home />} />

          {/* Legal pages */}
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-of-service" element={<TermsOfService />} />

          {/* Standard Layout Routes */}
          <Route
            path="*"
            element={
              <Box
                id="app"
                className="d-flex flex-column h-100"
                sx={{
                  backgroundColor: colors.background,
                  color: colors.text.primary,
                }}
              >
                <NavBar />
                <Container
                  style={{
                    paddingTop: "100px",
                    backgroundColor: colors.background,
                  }}
                >
                  <Box
                    className="route-transition"
                    sx={{ position: "relative", width: "100%" }}
                  >
                    <Routes>
                      {/* Dashboard - requires authentication but not admin */}
                      <Route
                        path="/dashboard"
                        element={<AuthenticationRoute component={Dashboard} />}
                      />

                      {/* Profile - requires login but not admin */}
                      <Route
                        path="/profile"
                        element={<AuthenticationRoute component={Profile} />}
                      />

                      {/* Settings - requires login but not admin */}
                      <Route
                        path="/settings"
                        element={<AuthenticationRoute component={Settings} />}
                      />

                      {/* Protected Routes */}
                      <Route
                        path="/dev-console"
                        element={
                          <ProtectedRoute
                            component={DevConsole}
                            authorizationCheck={isMongoDBAdmin}
                            redirectPath="/dashboard"
                          />
                        }
                      />
                      <Route
                        path="/mongodb-viewer"
                        element={
                          <ProtectedRoute
                            component={MongoDBViewer}
                            authorizationCheck={isMongoDBAdmin}
                            redirectPath="/dashboard"
                          />
                        }
                      />
                    </Routes>
                  </Box>
                </Container>
                <Footer />
              </Box>
            }
          />
        </Routes>
      </Router>
    </MixpanelProvider>
  );
};

export default App;

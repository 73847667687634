/**
 * Role-based access control utilities
 */

/**
 * Get the authenticated user data from our database
 * @param {Object} auth0User - The Auth0 user object
 * @param {String} accessToken - Authentication token
 * @returns {Promise<Object|null>} - The user data from our database or null if not found
 */
export const getAuthenticatedUser = async (auth0User, accessToken) => {
  if (!auth0User || !auth0User.sub) return null;

  try {
    if (!accessToken) return null;

    const apiOrigin =
      process.env.NODE_ENV === "production"
        ? "https://www.gazz.io"
        : "http://localhost:8080";

    const response = await fetch(
      `${apiOrigin}/api/auth/user/${auth0User.sub}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (!response.ok) {
      if (response.status === 404) {
        // User doesn't exist yet, create it
        return await createAuthenticatedUser(auth0User, accessToken);
      }
      throw new Error(`HTTP error ${response.status}`);
    }

    // User exists - now update the lastLogin timestamp
    const userData = await response.json();

    // Update lastLogin by calling the POST endpoint
    try {
      const updateResponse = await fetch(`${apiOrigin}/api/auth/user`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          auth0Id: auth0User.sub,
          email: auth0User.email,
          name: auth0User.name || auth0User.nickname,
        }),
      });

      if (updateResponse.ok) {
        // Return the updated user data
        return await updateResponse.json();
      }
    } catch (updateError) {
      console.error("Error updating lastLogin timestamp:", updateError);
      // If update fails, still return the original user data
    }

    return userData;
  } catch (error) {
    console.error("Error getting authenticated user:", error);
    return null;
  }
};

/**
 * Create a new user in our database from Auth0 data
 * @param {Object} auth0User - The Auth0 user object
 * @param {String} token - Authentication token
 * @returns {Promise<Object|null>} - The created user data or null if creation failed
 */
const createAuthenticatedUser = async (auth0User, token) => {
  try {
    if (!auth0User || !auth0User.sub) return null;
    if (!token) return null;

    const apiOrigin =
      process.env.NODE_ENV === "production"
        ? "https://www.gazz.io"
        : "http://localhost:8080";

    // We need to send user information in the request body
    const response = await fetch(`${apiOrigin}/api/auth/user`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        auth0Id: auth0User.sub,
        email: auth0User.email,
        name: auth0User.name || auth0User.nickname,
      }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error("Error creating authenticated user:", error);
    return null;
  }
};

import React, { useState, useEffect, useCallback } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { getConfig } from "../config";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import RefreshIcon from "@mui/icons-material/Refresh";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "./DevConsole.css";
import { codeStyles, colors } from "../theme";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

/**
 * MongoDB Viewer Component
 *
 * A protected component that provides a UI for viewing, adding, editing, and deleting
 * users from the MongoDB database. It displays user data in a paginated table and
 * provides forms for modifying user information.
 *
 * Also displays email signups from interested users in a separate tab.
 */
export const MongoDBViewerComponent = () => {
  // Configuration and API endpoint
  const { audience } = getConfig();
  const apiOrigin =
    process.env.NODE_ENV === "production"
      ? "https://www.gazz.io"
      : "http://localhost:8080";

  // State management
  // Users data and loading state
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [dbInfo, setDbInfo] = useState({
    database: process.env.REACT_APP_DATABASE_NAME || "",
    collection: "authusers",
  });

  // Email signups data and loading state
  const [emailSignups, setEmailSignups] = useState([]);
  const [emailSignupsLoading, setEmailSignupsLoading] = useState(true);
  const [emailSignupsError, setEmailSignupsError] = useState(null);

  // Tab state
  const [activeTab, setActiveTab] = useState(0);

  // Pagination state
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [emailSignupsPage, setEmailSignupsPage] = useState(0);
  const [emailSignupsRowsPerPage, setEmailSignupsRowsPerPage] = useState(10);

  // Dialog control states
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);

  // Notification state
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  // Auth0 hooks
  const { getAccessTokenSilently } = useAuth0();

  /**
   * Fetch access token from Auth0
   * Uses useCallback to prevent unnecessary re-renders
   *
   * @returns {Promise<string|null>} The access token or null if error
   */
  const fetchAccessToken = useCallback(async () => {
    try {
      return await getAccessTokenSilently();
    } catch (error) {
      console.error("Error fetching access token", error);
      setError("Failed to authenticate. Please try refreshing the page.");
      return null;
    }
  }, [getAccessTokenSilently]);

  /**
   * Fetch database metadata including collection name
   * Uses useCallback to prevent unnecessary re-renders
   *
   * @returns {Promise<void>}
   */
  const fetchDbMetadata = useCallback(async () => {
    try {
      // Set database info directly from environment variables
      setDbInfo({
        database: process.env.REACT_APP_DATABASE_NAME || "",
        collection: "authusers",
      });
    } catch (error) {
      console.error("Error setting database metadata:", error);
    }
  }, []);

  /**
   * Fetch users from MongoDB through the API
   * Uses useCallback to prevent unnecessary re-renders
   *
   * @returns {Promise<void>}
   */
  const fetchUsers = useCallback(async () => {
    try {
      setLoading(true);
      const token = await fetchAccessToken();
      if (!token) return;

      const response = await fetch(`${apiOrigin}/api/auth/users`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error ${response.status}`);
      }

      const data = await response.json();
      setUsers(data);
    } catch (error) {
      setError(`Failed to load users: ${error.message}`);
      console.error("Error fetching users:", error);
    } finally {
      setLoading(false);
    }
  }, [apiOrigin, fetchAccessToken]);

  /**
   * Fetch email signups from MongoDB through the API
   * Uses useCallback to prevent unnecessary re-renders
   *
   * @returns {Promise<void>}
   */
  const fetchEmailSignups = useCallback(async () => {
    try {
      setEmailSignupsLoading(true);
      const token = await fetchAccessToken();
      if (!token) return;

      const response = await fetch(`${apiOrigin}/api/auth/email-signups`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error ${response.status}`);
      }

      const data = await response.json();
      setEmailSignups(data);
    } catch (error) {
      setEmailSignupsError(`Failed to load email signups: ${error.message}`);
      console.error("Error fetching email signups:", error);
    } finally {
      setEmailSignupsLoading(false);
    }
  }, [apiOrigin, fetchAccessToken]);

  /**
   * Get the current collection name based on active tab
   *
   * @returns {string} The current collection name
   */
  const getCurrentCollection = () => {
    return activeTab === 0 ? "emailsignups" : "authusers";
  };

  /**
   * Handle tab change
   *
   * @param {Event} event - The event object
   * @param {number} newValue - The new tab index
   */
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    // Reset pagination when switching tabs
    if (newValue === 0) {
      setEmailSignupsPage(0);
    } else {
      setPage(0);
    }
  };

  // Load metadata and users when component mounts
  useEffect(() => {
    fetchDbMetadata();
    fetchUsers();
    fetchEmailSignups();
  }, [fetchDbMetadata, fetchUsers, fetchEmailSignups]);

  /**
   * Handle page change in the table pagination
   *
   * @param {Event} event - The event object
   * @param {number} newPage - The new page number
   */
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  /**
   * Handle rows per page change in the table pagination
   *
   * @param {Event} event - The event containing the new rows per page value
   */
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  /**
   * Open the dialog for confirming user deletion
   *
   * @param {Object} user - The user to delete
   */
  const handleOpenDeleteDialog = (user) => {
    setUserToDelete(user);
    setDeleteDialog(true);
  };

  /**
   * Close the delete confirmation dialog
   */
  const handleCloseDeleteDialog = () => {
    setDeleteDialog(false);
    setUserToDelete(null);
  };

  /**
   * Delete a user after confirmation
   */
  const handleDeleteUser = async () => {
    try {
      const token = await fetchAccessToken();
      if (!token) return;

      const response = await fetch(
        `${apiOrigin}/api/auth/users/${userToDelete._id}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || `HTTP error ${response.status}`);
      }

      setDeleteDialog(false);
      fetchUsers();
      setSnackbar({
        open: true,
        message: "User deleted successfully",
        severity: "success",
      });
    } catch (error) {
      setError(`Failed to delete user: ${error.message}`);
      console.error("Error deleting user:", error);
    }
  };

  /**
   * Handle page change in the email signups table pagination
   *
   * @param {Event} event - The event object
   * @param {number} newPage - The new page number
   */
  const handleEmailSignupsChangePage = (event, newPage) => {
    setEmailSignupsPage(newPage);
  };

  /**
   * Handle rows per page change in the email signups table pagination
   *
   * @param {Event} event - The event containing the new rows per page value
   */
  const handleEmailSignupsChangeRowsPerPage = (event) => {
    setEmailSignupsRowsPerPage(parseInt(event.target.value, 10));
    setEmailSignupsPage(0);
  };

  /**
   * Delete an email signup record
   *
   * @param {string} id - The ID of the email signup to delete
   */
  const handleDeleteEmailSignup = async (id) => {
    try {
      const token = await fetchAccessToken();
      if (!token) return;

      const response = await fetch(
        `${apiOrigin}/api/auth/email-signups/${id}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || `HTTP error ${response.status}`);
      }

      fetchEmailSignups();
      setSnackbar({
        open: true,
        message: "Email signup record deleted successfully",
        severity: "success",
      });
    } catch (error) {
      setError(`Failed to delete email signup: ${error.message}`);
      console.error("Error deleting email signup:", error);
    }
  };

  /**
   * Close the notification snackbar
   */
  const handleCloseSnackbar = () => {
    setSnackbar({
      ...snackbar,
      open: false,
    });
  };

  return (
    <Box className="mb-5" sx={{ maxWidth: 1200, mx: "auto", px: 2 }}>
      {/* Database information banner */}
      <Card variant="outlined" className="api-status-container" sx={{ mb: 3 }}>
        <CardContent sx={{ p: 2, "&:last-child": { pb: 2 } }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography
              variant="h6"
              className="environment-label"
              sx={{ fontSize: "1.1rem", fontWeight: 600, mr: 2 }}
            >
              Database Collection
            </Typography>
            <code className="environment-value" style={codeStyles}>
              {dbInfo.database}.{getCurrentCollection()}
            </code>
          </Box>
        </CardContent>
      </Card>

      {/* Tabs for switching between Users and Email Signups */}
      <Box sx={{ mb: 3 }}>
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          textColor="primary"
          indicatorColor="primary"
          sx={{
            "& .MuiTab-root": {
              outline: "none",
              "&:focus": {
                outline: "none",
              },
            },
          }}
        >
          <Tab
            label="Email Signups"
            disableRipple
            sx={{ "&:focus-visible": { outline: "none" } }}
          />
          <Tab
            label="MongoDB Users"
            disableRipple
            sx={{ "&:focus-visible": { outline: "none" } }}
          />
        </Tabs>
      </Box>

      {/* Email Signups Tab Panel */}
      {activeTab === 0 && (
        <>
          {/* Header with title and action buttons */}
          <Box
            sx={{
              mb: 3,
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              justifyContent: "space-between",
              alignItems: { xs: "flex-start", sm: "center" },
              gap: 2,
            }}
          >
            <Typography
              variant="h5"
              component="h2"
              sx={{ fontWeight: 500, color: colors.text.primary }}
            >
              Email Signups
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: 2,
              }}
            >
              <Button
                variant="outlined"
                startIcon={<RefreshIcon />}
                onClick={fetchEmailSignups}
                disabled={emailSignupsLoading}
              >
                Refresh
              </Button>
            </Box>
          </Box>

          {/* Error alert */}
          {emailSignupsError && (
            <Alert severity="error" sx={{ mb: 3 }}>
              {emailSignupsError}
            </Alert>
          )}

          {/* Email Signups data table */}
          <Paper
            elevation={2}
            sx={{ width: "100%", mb: 3, backgroundColor: colors.paper }}
          >
            <TableContainer sx={{ maxHeight: 600 }}>
              <Table stickyHeader aria-label="email signups table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{
                        backgroundColor: colors.paper,
                        color: colors.text.primary,
                      }}
                    >
                      Email
                    </TableCell>
                    <TableCell
                      sx={{
                        backgroundColor: colors.paper,
                        color: colors.text.primary,
                      }}
                    >
                      Created At
                    </TableCell>
                    <TableCell
                      sx={{
                        backgroundColor: colors.paper,
                        color: colors.text.primary,
                      }}
                    >
                      IP Address
                    </TableCell>
                    <TableCell
                      sx={{
                        backgroundColor: colors.paper,
                        color: colors.text.primary,
                      }}
                    >
                      User Agent
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{
                        backgroundColor: colors.paper,
                        color: colors.text.primary,
                      }}
                    >
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {emailSignupsLoading ? (
                    // Loading state
                    <TableRow>
                      <TableCell
                        colSpan={5}
                        align="center"
                        sx={{ py: 3, color: colors.text.secondary }}
                      >
                        <CircularProgress size={40} />
                        <Typography
                          variant="body2"
                          sx={{ mt: 2, color: colors.text.secondary }}
                        >
                          Loading email signups...
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ) : emailSignups.length === 0 ? (
                    // Empty state
                    <TableRow>
                      <TableCell
                        colSpan={5}
                        align="center"
                        sx={{ py: 3, color: colors.text.secondary }}
                      >
                        <Typography variant="body1">
                          No email signups found
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ) : (
                    // Email signup rows with pagination
                    emailSignups
                      .slice(
                        emailSignupsPage * emailSignupsRowsPerPage,
                        emailSignupsPage * emailSignupsRowsPerPage +
                          emailSignupsRowsPerPage
                      )
                      .map((signup) => (
                        <TableRow key={signup._id} hover>
                          <TableCell sx={{ color: colors.text.primary }}>
                            {signup.email}
                          </TableCell>
                          <TableCell sx={{ color: colors.text.secondary }}>
                            {new Date(signup.createdAt).toLocaleString()}
                          </TableCell>
                          <TableCell sx={{ color: colors.text.secondary }}>
                            {signup.ip || "N/A"}
                          </TableCell>
                          <TableCell
                            sx={{
                              color: colors.text.secondary,
                              maxWidth: "200px",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {signup.userAgent || "N/A"}
                          </TableCell>
                          <TableCell align="right">
                            <IconButton
                              size="small"
                              onClick={() =>
                                handleDeleteEmailSignup(signup._id)
                              }
                              aria-label="delete"
                              color="error"
                            >
                              <DeleteIcon fontSize="small" />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {/* Pagination controls */}
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={emailSignups.length}
              rowsPerPage={emailSignupsRowsPerPage}
              page={emailSignupsPage}
              onPageChange={handleEmailSignupsChangePage}
              onRowsPerPageChange={handleEmailSignupsChangeRowsPerPage}
              sx={{ color: colors.text.secondary }}
            />
          </Paper>
        </>
      )}

      {/* Users Tab Panel */}
      {activeTab === 1 && (
        <>
          {/* Header with title and action buttons */}
          <Box
            sx={{
              mb: 3,
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              justifyContent: "space-between",
              alignItems: { xs: "flex-start", sm: "center" },
              gap: 2,
            }}
          >
            <Typography
              variant="h5"
              component="h2"
              sx={{ fontWeight: 500, color: colors.text.primary }}
            >
              MongoDB Users
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: 2,
              }}
            >
              <Button
                variant="outlined"
                startIcon={<RefreshIcon />}
                onClick={fetchUsers}
                disabled={loading}
              >
                Refresh
              </Button>
            </Box>
          </Box>

          {/* Error alert */}
          {error && (
            <Alert severity="error" sx={{ mb: 3 }}>
              {error}
            </Alert>
          )}

          {/* Users data table */}
          <Paper
            elevation={2}
            sx={{ width: "100%", mb: 3, backgroundColor: colors.paper }}
          >
            <TableContainer sx={{ maxHeight: 600 }}>
              <Table stickyHeader aria-label="users table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{
                        backgroundColor: colors.paper,
                        color: colors.text.primary,
                      }}
                    >
                      Name
                    </TableCell>
                    <TableCell
                      sx={{
                        backgroundColor: colors.paper,
                        color: colors.text.primary,
                      }}
                    >
                      Email
                    </TableCell>
                    <TableCell
                      sx={{
                        backgroundColor: colors.paper,
                        color: colors.text.primary,
                      }}
                    >
                      Role
                    </TableCell>
                    <TableCell
                      sx={{
                        backgroundColor: colors.paper,
                        color: colors.text.primary,
                      }}
                    >
                      Created At
                    </TableCell>
                    <TableCell
                      sx={{
                        backgroundColor: colors.paper,
                        color: colors.text.primary,
                      }}
                    >
                      Last Login
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{
                        backgroundColor: colors.paper,
                        color: colors.text.primary,
                      }}
                    >
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading ? (
                    // Loading state
                    <TableRow>
                      <TableCell
                        colSpan={6}
                        align="center"
                        sx={{ py: 3, color: colors.text.secondary }}
                      >
                        <CircularProgress size={40} />
                        <Typography
                          variant="body2"
                          sx={{ mt: 2, color: colors.text.secondary }}
                        >
                          Loading users...
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ) : users.length === 0 ? (
                    // Empty state
                    <TableRow>
                      <TableCell
                        colSpan={6}
                        align="center"
                        sx={{ py: 3, color: colors.text.secondary }}
                      >
                        <Typography variant="body1">No users found</Typography>
                      </TableCell>
                    </TableRow>
                  ) : (
                    // User rows with pagination
                    users
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((user) => (
                        <TableRow key={user._id} hover>
                          <TableCell sx={{ color: colors.text.primary }}>
                            {user.name}
                          </TableCell>
                          <TableCell sx={{ color: colors.text.secondary }}>
                            {user.email}
                          </TableCell>
                          <TableCell>
                            <span
                              style={{
                                textTransform: "capitalize",
                                backgroundColor:
                                  user.role === "admin"
                                    ? colors.primary
                                    : colors.secondary,
                                color: colors.text.primary,
                                padding: "4px 8px",
                                borderRadius: "4px",
                                fontSize: "0.75rem",
                                fontWeight: "500",
                              }}
                            >
                              {user.role}
                            </span>
                          </TableCell>
                          <TableCell sx={{ color: colors.text.secondary }}>
                            {new Date(user.createdAt).toLocaleString()}
                          </TableCell>
                          <TableCell sx={{ color: colors.text.secondary }}>
                            {new Date(user.lastLogin).toLocaleString()}
                          </TableCell>
                          <TableCell align="right">
                            <IconButton
                              size="small"
                              onClick={() => handleOpenDeleteDialog(user)}
                              aria-label="delete"
                              color="error"
                            >
                              <DeleteIcon fontSize="small" />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {/* Pagination controls */}
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={users.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              sx={{ color: colors.text.secondary }}
            />
          </Paper>
        </>
      )}

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={deleteDialog}
        onClose={handleCloseDeleteDialog}
        aria-labelledby="delete-dialog-title"
        PaperProps={{
          sx: {
            backgroundColor: colors.paper,
            color: colors.text.primary,
          },
        }}
      >
        <DialogTitle id="delete-dialog-title">Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ color: colors.text.secondary }}>
            Are you sure you want to delete the user "{userToDelete?.name}"?
            This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog}>Cancel</Button>
          <Button onClick={handleDeleteUser} color="error" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default MongoDBViewerComponent;

export function getConfig() {
  const domain = process.env.REACT_APP_AUTH0_DOMAIN || "login.gazz.io";
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
  const audience =
    process.env.REACT_APP_AUTH0_AUDIENCE || "new-gazz-backend-api";

  // Only include audience if it's a valid value
  const validAudience =
    audience && audience !== "YOUR_API_IDENTIFIER" ? audience : null;

  if (!domain || !clientId) {
    throw new Error(
      "Please make sure that REACT_APP_AUTH0_DOMAIN and REACT_APP_AUTH0_CLIENT_ID environment variables are set"
    );
  }

  return {
    domain,
    clientId,
    ...(validAudience ? { audience: validAudience } : null),
  };
}

import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "./Loading";
import { useDbUser } from "../utils/useDbUser";

/**
 * A component that protects routes based on authentication and database role requirements
 * @param {Object} props - Component props
 * @param {Function} props.component - Component to render if authenticated and authorized
 * @param {Function} props.authorizationCheck - Function that checks if user is authorized based on database role
 * @param {String} props.redirectPath - Path to redirect to if not authorized
 * @returns {JSX.Element} - Protected route component
 */
export const ProtectedRoute = ({
  component,
  authorizationCheck = () => true,
  redirectPath = "/",
  ...args
}) => {
  const { user } = useAuth0();
  const { dbUser, isLoading: isDbUserLoading } = useDbUser();

  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => <Loading />,
  });

  // Show loading while we're fetching the database user data
  if (isDbUserLoading) {
    return <Loading />;
  }

  // If the user is authenticated but fails the database authorization check, redirect
  // Using database roles for authorization
  if (user && dbUser && !authorizationCheck(user, dbUser)) {
    return <Navigate to={redirectPath} replace />;
  }

  return <Component {...args} />;
};

export default ProtectedRoute;

import React, { useState, useEffect, useCallback } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from "react-google-recaptcha-v3";

// Add global style to hide reCAPTCHA badge
const globalStyles = `
  .grecaptcha-badge {
    visibility: hidden !important;
  }
`;

// Email form component with reCAPTCHA integration
const EmailSignupForm = () => {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  // Get the reCAPTCHA execution function
  const { executeRecaptcha } = useGoogleReCaptcha();

  // Email validation
  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  // Handle email input change
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setEmailError("");
  };

  // Handle email submission
  const handleSubmit = async () => {
    // Validate email
    if (!email) {
      setEmailError("Email is required");
      return;
    }

    if (!validateEmail(email)) {
      setEmailError("Please enter a valid email address");
      return;
    }

    if (!executeRecaptcha) {
      setSnackbar({
        open: true,
        message: "reCAPTCHA not available. Please try again later.",
        severity: "error",
      });
      return;
    }

    setIsSubmitting(true);

    try {
      // Execute reCAPTCHA and get token
      const recaptchaToken = await executeRecaptcha("email_signup");

      // Determine API URL based on environment
      const apiUrl =
        process.env.NODE_ENV === "production"
          ? `${window.location.origin}/api/email-signup`
          : "http://localhost:8080/api/email-signup";

      const response = await axios.post(apiUrl, {
        email,
        recaptchaToken,
      });

      // Show success message
      setSnackbar({
        open: true,
        message: response.data.isNew
          ? "Thank you for signing up! We'll keep you updated."
          : "You're already signed up! Thanks for your interest.",
        severity: "success",
      });

      // Clear email field
      setEmail("");
    } catch (error) {
      console.error("Error submitting email:", error);

      // Show error message
      const errorMessage =
        error.response?.data?.message ||
        "An error occurred. Please try again later.";
      setSnackbar({
        open: true,
        message: errorMessage,
        severity: "error",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  // Handle close snackbar
  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <>
      <Box sx={{ mt: 10, mb: 2, width: "100%" }}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Be the first to know
        </Typography>
        <Box
          component="form"
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            justifyContent: "center",
            width: "100%",
            maxWidth: { xs: "100%", sm: "500px" },
            mx: "auto",
            gap: 2,
            px: { xs: 4, sm: 0 },
            position: "relative",
          }}
        >
          <Box
            sx={{
              width: "100%",
              position: "relative",
              pb: emailError ? 3 : 0,
            }}
          >
            <TextField
              placeholder="Email *"
              variant="outlined"
              fullWidth
              value={email}
              onChange={handleEmailChange}
              error={!!emailError}
              helperText={emailError}
              disabled={isSubmitting}
              sx={{
                width: "100%",
                "& .MuiOutlinedInput-root": {
                  borderRadius: "50px",
                  backgroundColor: "rgba(255,255,255,0.05)",
                  "& fieldset": {
                    borderColor: "rgba(255,255,255,0.2)",
                  },
                  "&:hover fieldset": {
                    borderColor: "rgba(255,255,255,0.4)",
                  },
                  "&.Mui-focused": {
                    backgroundColor: "rgba(255,255,255,0.05)",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "rgba(255,255,255,0.5)",
                    borderWidth: 1,
                  },
                  // Add autofill override styles
                  "& input:-webkit-autofill": {
                    WebkitBoxShadow:
                      "0 0 0 100px rgba(45, 45, 45, 0.8) inset !important",
                    WebkitTextFillColor: "white !important",
                    caretColor: "white",
                    borderRadius: "inherit",
                    transition: "background-color 5000s ease-in-out 0s",
                  },
                },
                "& .MuiOutlinedInput-input": {
                  color: "white",
                  "&::placeholder": {
                    color: "rgba(255,255,255,0.7)",
                    opacity: 1,
                  },
                },
                "&:focus": {
                  outline: "none",
                },
                "& .MuiOutlinedInput-root:focus": {
                  outline: "none",
                  boxShadow: "none",
                },
                // Position error message absolutely to prevent layout shifts
                "& .MuiFormHelperText-root": {
                  color: "#f44336",
                  marginLeft: 2,
                  position: "absolute",
                  bottom: "-24px",
                  fontSize: "0.8rem",
                },
              }}
              InputProps={{
                inputProps: {
                  style: { padding: "10px 20px" },
                },
              }}
            />
          </Box>
          <Button
            type="submit"
            variant="contained"
            disabled={isSubmitting}
            sx={{
              borderRadius: "50px",
              backgroundColor: "#4F46E5",
              px: 4,
              minWidth: { xs: "100%", sm: "auto" },
              height: { sm: "40px" }, // Fixed height on desktop
              alignSelf: { sm: "flex-start" }, // Align to top for desktop
              textTransform: "uppercase",
              fontWeight: "bold",
              "&:focus": {
                outline: "none",
                boxShadow: "none",
              },
              "&:hover": {
                backgroundColor: "#4338ca",
              },
              "&.Mui-disabled": {
                backgroundColor: "rgba(79, 70, 229, 0.5)",
                color: "rgba(255, 255, 255, 0.7)",
              },
            }}
          >
            {isSubmitting ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              "SUBMIT"
            )}
          </Button>
        </Box>

        {/* Required reCAPTCHA disclosure text */}
        <Typography
          variant="caption"
          sx={{
            mt: emailError ? 0 : 2,
            display: "block",
            color: "rgba(255,255,255,0.6)",
            fontSize: "0.7rem",
            maxWidth: "500px",
            mx: "auto",
            textAlign: "center",
          }}
        >
          This site is protected by reCAPTCHA and the Google
          <Typography
            component="a"
            variant="caption"
            href="https://policies.google.com/privacy"
            target="_blank"
            rel="noopener noreferrer"
            sx={{
              color: "rgba(255,255,255,0.8)",
              textDecoration: "none",
              "&:hover": {
                textDecoration: "underline",
              },
              mx: 0.5,
              fontSize: "0.7rem",
            }}
          >
            Privacy Policy
          </Typography>
          and
          <Typography
            component="a"
            variant="caption"
            href="https://policies.google.com/terms"
            target="_blank"
            rel="noopener noreferrer"
            sx={{
              color: "rgba(255,255,255,0.8)",
              textDecoration: "none",
              "&:hover": {
                textDecoration: "underline",
              },
              mx: 0.5,
              fontSize: "0.7rem",
            }}
          >
            Terms of Service
          </Typography>
          apply.
        </Typography>
      </Box>

      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        sx={{ zIndex: 9999, mb: 8 }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
};

const Hero = () => {
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  // Use Auth0 login functionality
  const { loginWithRedirect, isAuthenticated } = useAuth0();

  // If the user is already authenticated, we can check for admin status
  useEffect(() => {
    if (isAuthenticated) {
      // Redirect to dashboard if already authenticated
      window.location.href = "/dashboard";
    }
  }, [isAuthenticated]);

  useEffect(() => {
    // Set target date to April 15th, 12:00 AM Eastern Time
    const calculateTimeLeft = () => {
      try {
        // Create target date - April 15th at 12:00 AM ET
        const targetDate = new Date("April 15, 2025 00:00:00 EDT");

        // Get current date adjusted for user's timezone
        const now = new Date();

        // Calculate time difference in milliseconds
        const difference = targetDate - now;

        if (difference > 0) {
          // Convert to days, hours, minutes, seconds
          const days = Math.floor(difference / (1000 * 60 * 60 * 24));
          const hours = Math.floor(
            (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
          );
          const minutes = Math.floor(
            (difference % (1000 * 60 * 60)) / (1000 * 60)
          );
          const seconds = Math.floor((difference % (1000 * 60)) / 1000);

          return { days, hours, minutes, seconds };
        } else {
          // If we're past the target date
          return { days: 0, hours: 0, minutes: 0, seconds: 0 };
        }
      } catch (error) {
        console.error("Error calculating time left:", error);
        // Fallback to a fixed countdown if there's an error
        return { days: 30, hours: 23, minutes: 59, seconds: 59 };
      }
    };

    // Calculate initial time left
    setTimeLeft(calculateTimeLeft());

    // Set up the interval to update the countdown
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const formatNumber = (num) => {
    return num < 10 ? `0${num}` : num;
  };

  // Custom login handler that specifies the redirect URI
  const handleLogin = () => {
    loginWithRedirect({
      appState: {
        returnTo: "/dashboard",
      },
    });
  };

  // Get reCAPTCHA key from environment variable
  const recaptchaKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={recaptchaKey}
      container={{
        parameters: {
          badge: "inline",
        },
      }}
    >
      {/* Inject global styles to hide reCAPTCHA badge */}
      <style>{globalStyles}</style>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100vh",
          width: "100vw",
          margin: 0,
          padding: 0,
          position: "relative",
          overflow: "hidden",
          background: "linear-gradient(45deg, #9333ea 0%, #3b0764 100%)",
        }}
      >
        {/* Login button in top right */}
        <Box
          sx={{
            position: "absolute",
            top: 20,
            right: 20,
            zIndex: 10,
          }}
        >
          <Button
            color="inherit"
            variant="outlined"
            size="small"
            sx={{
              borderRadius: "20px",
              px: 3,
              py: 0.5,
              color: "white",
              borderColor: "rgba(255,255,255,0.3)",
              "&:hover": {
                borderColor: "rgba(255,255,255,0.8)",
                backgroundColor: "rgba(255,255,255,0.1)",
              },
              "&:focus": {
                outline: "none",
                boxShadow: "none",
              },
            }}
            onClick={handleLogin}
          >
            Log in
          </Button>
        </Box>

        {/* Circle gradient overlay */}
        <Box
          sx={{
            position: "absolute",
            width: "80vw",
            height: "80vw",
            borderRadius: "50%",
            background:
              "radial-gradient(circle, rgba(79, 70, 229, 0.4) 0%, rgba(0, 0, 0, 0) 70%)",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 0,
          }}
        />

        {/* Main content */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            color: "white",
            position: "relative",
            zIndex: 1,
            width: "100%",
            height: "100%",
            padding: 4,
          }}
        >
          <Typography
            variant="h1"
            component="h1"
            sx={{
              fontSize: { xs: "3rem", md: "4.5rem" },
              fontWeight: 600,
              mb: 2,
            }}
          >
            Gazz.io
          </Typography>
          <Typography
            variant="h2"
            component="h2"
            sx={{
              fontSize: { xs: "2rem", md: "3rem" },
              fontWeight: 500,
              mb: 8,
              opacity: 0.9,
            }}
          >
            Coming Soon
          </Typography>

          {/* Countdown timer */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mb: 6,
              "& > div": {
                mx: { xs: 1, sm: 2 },
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              },
            }}
          >
            <Box>
              <Typography
                variant="h1"
                component="div"
                sx={{
                  fontWeight: 500,
                  fontSize: { xs: "3rem", sm: "4rem", md: "6rem" },
                  lineHeight: 1,
                }}
              >
                {formatNumber(timeLeft.days)}
              </Typography>
              <Typography variant="body1" sx={{ mt: 2 }}>
                Days
              </Typography>
            </Box>
            <Typography
              variant="h1"
              component="div"
              sx={{
                alignSelf: "flex-start",
                fontSize: { xs: "3rem", sm: "4rem", md: "6rem" },
                opacity: 0.8,
              }}
            >
              :
            </Typography>
            <Box>
              <Typography
                variant="h1"
                component="div"
                sx={{
                  fontWeight: 500,
                  fontSize: { xs: "3rem", sm: "4rem", md: "6rem" },
                  lineHeight: 1,
                }}
              >
                {formatNumber(timeLeft.hours)}
              </Typography>
              <Typography variant="body1" sx={{ mt: 2 }}>
                Hours
              </Typography>
            </Box>
            <Typography
              variant="h1"
              component="div"
              sx={{
                alignSelf: "flex-start",
                fontSize: { xs: "3rem", sm: "4rem", md: "6rem" },
                opacity: 0.8,
              }}
            >
              :
            </Typography>
            <Box>
              <Typography
                variant="h1"
                component="div"
                sx={{
                  fontWeight: 500,
                  fontSize: { xs: "3rem", sm: "4rem", md: "6rem" },
                  lineHeight: 1,
                }}
              >
                {formatNumber(timeLeft.minutes)}
              </Typography>
              <Typography variant="body1" sx={{ mt: 2 }}>
                Mins.
              </Typography>
            </Box>
            <Typography
              variant="h1"
              component="div"
              sx={{
                alignSelf: "flex-start",
                fontSize: { xs: "3rem", sm: "4rem", md: "6rem" },
                opacity: 0.8,
              }}
            >
              :
            </Typography>
            <Box>
              <Typography
                variant="h1"
                component="div"
                sx={{
                  fontWeight: 500,
                  fontSize: { xs: "3rem", sm: "4rem", md: "6rem" },
                  lineHeight: 1,
                }}
              >
                {formatNumber(timeLeft.seconds)}
              </Typography>
              <Typography variant="body1" sx={{ mt: 2 }}>
                Secs.
              </Typography>
            </Box>
          </Box>

          {/* Email signup with reCAPTCHA */}
          <EmailSignupForm />
        </Box>

        {/* Footer links */}
        <Box
          sx={{
            position: "absolute",
            bottom: 16,
            width: "100%",
            display: "flex",
            justifyContent: "center",
            gap: 4,
            zIndex: 1,
          }}
        >
          <Typography
            component="a"
            href="/privacy-policy"
            sx={{
              color: "rgba(255,255,255,0.7)",
              textDecoration: "none",
              fontSize: "0.875rem",
              "&:hover": {
                color: "rgba(255,255,255,1)",
                textDecoration: "underline",
              },
            }}
          >
            Privacy Policy
          </Typography>
          <Typography
            component="a"
            href="/terms-of-service"
            sx={{
              color: "rgba(255,255,255,0.7)",
              textDecoration: "none",
              fontSize: "0.875rem",
              "&:hover": {
                color: "rgba(255,255,255,1)",
                textDecoration: "underline",
              },
            }}
          >
            Terms of Service
          </Typography>
        </Box>
      </Box>
    </GoogleReCaptchaProvider>
  );
};

export default Hero;

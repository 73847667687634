import React, { useEffect } from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Auth0Provider } from "@auth0/auth0-react";
import { getConfig } from "./config";
import "@fontsource/roboto";
import "@fontsource/roboto-mono";
import { ThemeProvider } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import darkTheme from "./theme";
import injectThemeColors from "./theme-loader";

// Inject theme colors as CSS variables
injectThemeColors();

const onRedirectCallback = (appState) => {
  // Determine the correct return URL based on the environment and the current origin
  const returnToUrl =
    process.env.NODE_ENV === "production"
      ? window.location.origin
      : "http://localhost:3000/";

  // Use the returnTo from appState if available, otherwise use the determined URL
  const path = appState?.returnTo || returnToUrl;

  // Perform the redirection
  if (path.startsWith(window.location.origin)) {
    window.history.replaceState({}, document.title, path);
  } else {
    // Handle the mismatch by redirecting to a default path
    window.history.replaceState({}, document.title, "/");
  }
};

const config = getConfig();

const providerConfig = {
  domain: config.domain,
  clientId: config.clientId,
  onRedirectCallback,
  authorizationParams: {
    redirect_uri: window.location.origin,
    ...(config.audience ? { audience: config.audience } : null),
  },
  useRefreshTokens: true,
  cacheLocation: "localstorage",
};

// Simple wrapper component to apply theme changes when colors might change
const ThemedApp = () => {
  useEffect(() => {
    // Re-inject theme colors on component mount
    injectThemeColors();
  }, []);

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <App />
    </ThemeProvider>
  );
};

const root = createRoot(document.getElementById("root"));
root.render(
  <Auth0Provider {...providerConfig}>
    <ThemedApp />
  </Auth0Provider>
);

serviceWorker.unregister();

import mixpanel from "mixpanel-browser";

// Get the Mixpanel token from environment variables
const MIXPANEL_TOKEN = process.env.REACT_APP_MIXPANEL_TOKEN || "";

// Create a mock/noop implementation for when token is missing
const noopMixpanel = {
  init: () => {},
  track: () => {},
  identify: () => {},
  reset: () => {},
  people: {
    set: () => {},
  },
  get_session_recording_properties: () => ({}),
  register: () => {},
};

// Initialize Mixpanel with session replay enabled
const initMixpanel = () => {
  // Only initialize if we have a valid token (not empty string)
  if (MIXPANEL_TOKEN && MIXPANEL_TOKEN !== "") {
    mixpanel.init(MIXPANEL_TOKEN, {
      debug: process.env.NODE_ENV !== "production",
      track_pageview: true,
      persistence: "localStorage",
      api_host: "https://api.mixpanel.com",
      // Enable session replay with proper settings
      record_sessions_percent: 100,
      record_block_class: "mp-no-record",
      record_mask_text_selector: "", // Don't mask any text
      record_input: true, // Record input values in forms
      capture_text_content: true, // Capture text content
      ignore_dnt: true,
    });

    return mixpanel;
  } else {
    return noopMixpanel;
  }
};

// Get either the initialized mixpanel instance or the noop one
const mp = initMixpanel();

// Track page views
const trackPageView = (pageName) => {
  mp.track("Page Viewed", {
    page_name: pageName,
    url: window.location.href,
    referrer: document.referrer || "direct",
  });
};

// Track user sign in
const trackSignIn = (user) => {
  if (user?.sub) {
    mp.identify(user.sub);

    // Set user properties
    mp.people.set({
      $name: user.name,
      $email: user.email,
      $avatar: user.picture,
      auth0_id: user.sub,
      last_login: new Date().toISOString(),
    });

    mp.track("User Signed In");
  }
};

// Track user sign out
const trackSignOut = () => {
  mp.track("User Signed Out");
  mp.reset();
};

// Track custom events
const track = (eventName, properties = {}) => {
  mp.track(eventName, properties);
};

// Get session recording properties to add to events tracked by other systems
const getSessionReplayProperties = () => {
  return mp.get_session_recording_properties();
};

export {
  initMixpanel,
  trackPageView,
  trackSignIn,
  trackSignOut,
  track,
  getSessionReplayProperties,
};

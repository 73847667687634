import { colors } from "./theme";

/**
 * Updates CSS variables with theme colors
 * This ensures the CSS variables match the theme.js colors
 */
export const injectThemeColors = () => {
  // Get the root element
  const root = document.documentElement;

  // Set primary color
  root.style.setProperty("--primary-color", colors.primary);

  // Set secondary color
  root.style.setProperty("--secondary-color", colors.secondary);

  // Set background colors
  root.style.setProperty("--background-color", colors.background);
  root.style.setProperty("--paper-color", colors.paper);

  // Set code colors
  root.style.setProperty("--code-background", colors.codeBackground);
  root.style.setProperty("--code-border", colors.codeBorder);

  // Set text colors
  root.style.setProperty("--text-primary", colors.text.primary);
  root.style.setProperty("--text-secondary", colors.text.secondary);

  console.log("Theme colors injected as CSS variables");
};

export default injectThemeColors;

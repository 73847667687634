import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { trackPageView } from "../utils/mixpanel";

/**
 * Component to track page views in Mixpanel
 * Place this component once in your app, typically near the Router
 */
const PageTracker = () => {
  const location = useLocation();

  useEffect(() => {
    // Extract the page name from the path (remove leading slash and use current path)
    const pageName = location.pathname.substring(1) || "home";

    // Track the page view
    trackPageView(pageName);
  }, [location]);

  // This component doesn't render anything
  return null;
};

export default PageTracker;

import React, { useState, useEffect } from "react";
import {
  NavLink as RouterNavLink,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { useDbUser } from "../utils/useDbUser";
import { colors } from "../theme";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Skeleton from "@mui/material/Skeleton";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useMixpanel } from "../contexts/MixpanelContext";
import { trackSignOut } from "../utils/mixpanel";

// Shared styles for better reusability
const sharedStyles = {
  noFocus: {
    "&:focus": {
      outline: "none",
    },
    "&.MuiButtonBase-root:focus-visible": {
      outline: "none",
      boxShadow: "none",
    },
  },
  tabStyle: {
    textTransform: "none",
    fontSize: "1rem",
    fontWeight: 500,
    opacity: 0.7,
    "&.Mui-selected": {
      opacity: 1,
    },
  },
};

const NavBar = () => {
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [tabValue, setTabValue] = useState(0);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { user, isAuthenticated, loginWithRedirect, logout } = useAuth0();
  const { dbUser, isLoading: isDbUserLoading } = useDbUser();
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const mixpanel = useMixpanel();

  // Loading state for navigation UI
  const isNavLoading = isAuthenticated && isDbUserLoading;

  // Check for admin access based on database user role
  const hasAdminAccess = isAuthenticated && dbUser && dbUser.role === "admin";

  // Set the correct tab based on the current path
  useEffect(() => {
    const path = location.pathname;
    if (path === "/dashboard") {
      setTabValue(0);
    } else if (path === "/dev-console") {
      setTabValue(1);
    } else if (path === "/mongodb-viewer") {
      setTabValue(2);
    }
  }, [location.pathname]);

  // Update drawer state when screen size changes
  useEffect(() => {
    if (!isMobile && drawerOpen) {
      setDrawerOpen(false);
    }
  }, [isMobile, drawerOpen]);

  // Handle opening the user profile menu
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  // Close the user profile menu
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  // Toggle drawer for mobile - modified to handle toggle case
  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  // Handle hamburger icon click to toggle drawer
  const handleHamburgerClick = () => {
    setDrawerOpen(!drawerOpen);
  };

  // Handle tab navigation change
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    if (newValue === 0) {
      navigate("/dashboard");
    } else if (newValue === 1) {
      navigate("/dev-console");
    } else if (newValue === 2) {
      navigate("/mongodb-viewer");
    }
  };

  // Handle clicking the Dashboard tab
  const handleDashboardClick = () => {
    setTabValue(0);
    navigate("/dashboard");
  };

  // Handle navigation from mobile drawer
  const handleNavigation = (path) => {
    navigate(path);
    setDrawerOpen(false);
  };

  // Handle user logout with redirect to home
  const logoutWithRedirect = () => {
    // Track sign out event before logging out
    trackSignOut();

    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };

  // Render the mobile drawer content
  const drawerContent = () => (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List sx={{ py: 0 }}>
        {/* App title/header */}
        <ListItem
          sx={{
            backgroundColor: colors.primary,
            color: colors.text.primary,
            py: 2,
            mb: 1,
          }}
        >
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            GAZZ.IO
          </Typography>
        </ListItem>

        <ListItem disablePadding>
          <ListItemButton
            onClick={() => handleNavigation("/dashboard")}
            selected={location.pathname === "/dashboard"}
            sx={{
              "&.Mui-selected": {
                backgroundColor: `${colors.primary}20`,
                borderLeft: `4px solid ${colors.primary}`,
              },
              pl: location.pathname === "/dashboard" ? 2 : 3,
              py: 1.5, // Consistent padding
            }}
          >
            <ListItemText primary="Dashboard" />
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding>
          <ListItemButton
            onClick={() => handleNavigation("/profile")}
            selected={location.pathname === "/profile"}
            sx={{
              "&.Mui-selected": {
                backgroundColor: `${colors.primary}20`,
                borderLeft: `4px solid ${colors.primary}`,
              },
              pl: location.pathname === "/profile" ? 2 : 3,
              py: 1.5, // Consistent padding
            }}
          >
            <ListItemText primary="Profile" />
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding>
          <ListItemButton
            onClick={() => handleNavigation("/settings")}
            selected={location.pathname === "/settings"}
            sx={{
              "&.Mui-selected": {
                backgroundColor: `${colors.primary}20`,
                borderLeft: `4px solid ${colors.primary}`,
              },
              pl: location.pathname === "/settings" ? 2 : 3,
              py: 1.5, // Consistent padding
            }}
          >
            <ListItemText primary="Settings" />
          </ListItemButton>
        </ListItem>

        {hasAdminAccess && (
          <>
            <ListItem disablePadding>
              <ListItemButton
                onClick={() => handleNavigation("/dev-console")}
                selected={location.pathname === "/dev-console"}
                sx={{
                  "&.Mui-selected": {
                    backgroundColor: `${colors.primary}20`,
                    borderLeft: `4px solid ${colors.primary}`,
                  },
                  pl: location.pathname === "/dev-console" ? 2 : 3,
                  py: 1.5, // Consistent padding
                }}
              >
                <ListItemText primary="Dev Console" />
              </ListItemButton>
            </ListItem>

            <ListItem disablePadding>
              <ListItemButton
                onClick={() => handleNavigation("/mongodb-viewer")}
                selected={location.pathname === "/mongodb-viewer"}
                sx={{
                  "&.Mui-selected": {
                    backgroundColor: `${colors.primary}20`,
                    borderLeft: `4px solid ${colors.primary}`,
                  },
                  pl: location.pathname === "/mongodb-viewer" ? 2 : 3,
                  py: 1.5, // Consistent padding
                }}
              >
                <ListItemText primary="MongoDB Viewer" />
              </ListItemButton>
            </ListItem>
          </>
        )}
      </List>
    </Box>
  );

  // Render nav tabs for desktop view
  const renderDesktopNavTabs = () => {
    // Show skeletons while loading database user data
    if (isNavLoading) {
      return (
        <Box
          sx={{ display: "flex", ml: 2, height: "64px", alignItems: "center" }}
        >
          <Skeleton
            variant="rectangular"
            width={120}
            height={40}
            sx={{ mr: 2, borderRadius: "4px" }}
          />
          <Skeleton
            variant="rectangular"
            width={120}
            height={40}
            sx={{ mr: 2, borderRadius: "4px" }}
          />
          <Skeleton
            variant="rectangular"
            width={150}
            height={40}
            sx={{ borderRadius: "4px" }}
          />
        </Box>
      );
    }

    // Show admin tabs
    if (hasAdminAccess) {
      return (
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          textColor="inherit"
          TabIndicatorProps={{
            style: {
              backgroundColor: colors.text.primary,
              height: 3,
              borderRadius: "3px 3px 0 0",
              position: "absolute",
              bottom: 0,
              transition: "all 0.3s cubic-bezier(0.4, 0, 0.2, 1)",
            },
          }}
          sx={{
            ml: 2,
            height: "100%",
            minHeight: "64px",
            "& .MuiTab-root": {
              ...sharedStyles.noFocus,
              height: "64px",
              minHeight: "64px !important",
              padding: "6px 16px",
            },
            "& .MuiTabs-indicator": {
              ...sharedStyles.noFocus,
              transition: "all 0.3s cubic-bezier(0.4, 0, 0.2, 1)",
            },
            "& .MuiTabs-flexContainer": {
              height: "100%",
              minHeight: "64px",
            },
            "& .MuiTabs-scroller": {
              height: "100%",
            },
            ...sharedStyles.noFocus,
          }}
        >
          <Tab
            label="Dashboard"
            disableFocusRipple
            disableRipple
            sx={{
              ...sharedStyles.tabStyle,
              minWidth: 120,
              ...sharedStyles.noFocus,
            }}
          />
          <Tab
            label="Dev Console"
            disableFocusRipple
            disableRipple
            sx={{
              ...sharedStyles.tabStyle,
              minWidth: 120,
              ...sharedStyles.noFocus,
            }}
          />
          <Tab
            label="MongoDB Viewer"
            disableFocusRipple
            disableRipple
            sx={{
              ...sharedStyles.tabStyle,
              minWidth: 150,
              ...sharedStyles.noFocus,
            }}
          />
        </Tabs>
      );
    }

    // Show regular user tabs (only Dashboard)
    if (isAuthenticated) {
      return (
        <Button
          onClick={handleDashboardClick}
          sx={{
            ml: 2,
            color: colors.text.primary,
            ...sharedStyles.tabStyle,
            borderBottom:
              location.pathname === "/dashboard"
                ? `3px solid ${colors.text.primary}`
                : "none",
            borderRadius: "0px",
            height: "64px",
            opacity: location.pathname === "/dashboard" ? 1 : 0.7,
          }}
        >
          Dashboard
        </Button>
      );
    }

    // Return null if not authenticated
    return null;
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        bgcolor: colors.primary,
        boxShadow: `0 4px 20px ${
          colors.shadowColor || "rgba(87, 49, 255, 0.25)"
        }`,
        backdropFilter: "blur(8px)",
        height: 64,
        zIndex: 1300,
      }}
    >
      <Container
        maxWidth="xl"
        sx={{
          height: "100%",
          width: "100%",
          position: "relative",
          margin: "0 auto",
        }}
      >
        <Toolbar
          disableGutters
          sx={{
            height: "100%",
            minHeight: "64px !important",
            width: "100%",
            padding: 0,
          }}
        >
          {/* Mobile hamburger menu - updated to toggle drawer */}
          {isMobile && isAuthenticated && (
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={handleHamburgerClick}
              sx={{ mr: 2, ...sharedStyles.noFocus }}
            >
              <MenuIcon />
            </IconButton>
          )}

          {/* Logo button */}
          <Typography
            variant="h6"
            component="div"
            sx={{
              mr: 2,
              fontWeight: 700,
              color: colors.text.primary,
              cursor: "pointer",
              fontFamily: "'Roboto', sans-serif",
              letterSpacing: "1px",
              ...sharedStyles.noFocus,
            }}
            onClick={() => navigate("/dashboard")}
          >
            GAZZ.IO
          </Typography>

          {/* Navigation tabs for desktop */}
          {!isMobile && renderDesktopNavTabs()}

          <Box sx={{ flexGrow: 1 }} />

          {/* Login or user profile section */}
          {!isAuthenticated ? (
            <Button
              variant="outlined"
              onClick={loginWithRedirect}
              disableFocusRipple
              disableRipple
              sx={{
                color: colors.text.primary,
                borderColor: `${colors.text.primary}80`,
                borderRadius: "20px",
                px: 3,
                textTransform: "none",
                fontSize: "0.9rem",
                fontWeight: 500,
                "&:hover": {
                  borderColor: colors.text.primary,
                  backgroundColor: `${colors.text.primary}20`,
                },
                ...sharedStyles.noFocus,
              }}
            >
              Log in
            </Button>
          ) : (
            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Account settings">
                <IconButton
                  onClick={handleOpenUserMenu}
                  disableFocusRipple
                  disableRipple
                  sx={{
                    p: 0,
                    border: `2px solid ${colors.text.primary}40`,
                    "&:hover": {
                      border: `2px solid ${colors.text.primary}80`,
                    },
                    transition: "all 0.2s",
                    ...sharedStyles.noFocus,
                  }}
                >
                  <Avatar
                    src={user.picture}
                    alt={user.name}
                    sx={{ width: 36, height: 36 }}
                  />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{
                  mt: "45px",
                  "& .MuiPaper-root": {
                    borderRadius: 2,
                    boxShadow: `0 5px 20px ${
                      colors.shadowColor || "rgba(0,0,0,0.15)"
                    }`,
                    minWidth: 180,
                  },
                  "& .MuiMenuItem-root": {
                    ...sharedStyles.noFocus,
                  },
                }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                keepMounted
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
                disableAutoFocusItem
                disableRestoreFocus
              >
                <MenuItem
                  onClick={() => {
                    handleCloseUserMenu();
                    navigate("/profile");
                  }}
                  disableRipple
                >
                  <Typography textAlign="center">Profile</Typography>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleCloseUserMenu();
                    navigate("/settings");
                  }}
                  disableRipple
                >
                  <Typography textAlign="center">Settings</Typography>
                </MenuItem>
                <MenuItem onClick={() => logoutWithRedirect()} disableRipple>
                  <Typography textAlign="center">Logout</Typography>
                </MenuItem>
              </Menu>
            </Box>
          )}
        </Toolbar>
      </Container>

      {/* Mobile navigation drawer */}
      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
        PaperProps={{
          sx: {
            backgroundColor: colors.background,
            color: colors.text.primary,
            pt: 2,
          },
        }}
      >
        {drawerContent()}
      </Drawer>
    </AppBar>
  );
};

export default NavBar;

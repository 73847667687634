import React, { createContext, useContext } from "react";
import {
  trackPageView,
  trackSignIn,
  trackSignOut,
  track,
  getSessionReplayProperties,
} from "../utils/mixpanel";

/**
 * Create a React Context for Mixpanel analytics functionality
 * This provides default no-op implementations if used outside a provider
 */
const MixpanelContext = createContext({
  trackPageView: () => {},
  trackSignIn: () => {},
  trackSignOut: () => {},
  track: () => {},
  getSessionReplayProperties: () => ({}),
});

/**
 * MixpanelProvider component
 *
 * Wraps application components and provides access to Mixpanel tracking functions
 * This allows any child component to track events without prop drilling
 *
 * @param {Object} props - Component props
 * @param {React.ReactNode} props.children - Child components that will have access to Mixpanel
 * @returns {JSX.Element} Provider component with children
 */
export const MixpanelProvider = ({ children }) => {
  // Create object with all the Mixpanel utility functions
  const mixpanelUtils = {
    trackPageView,
    trackSignIn,
    trackSignOut,
    track,
    getSessionReplayProperties,
  };

  return (
    <MixpanelContext.Provider value={mixpanelUtils}>
      {children}
    </MixpanelContext.Provider>
  );
};

/**
 * Custom hook to consume the Mixpanel context
 *
 * Components can use this hook to access Mixpanel tracking functions
 * Example: const { track } = useMixpanel(); track('Button Clicked');
 *
 * @returns {Object} Mixpanel utility functions object
 */
export const useMixpanel = () => {
  return useContext(MixpanelContext);
};

import React from "react";
import Hero from "../components/Hero";

const Home = () => (
  <div
    style={{
      margin: 0,
      padding: 0,
      width: "100vw",
      height: "100vh",
      overflow: "hidden",
    }}
  >
    <Hero />
  </div>
);

export default Home;

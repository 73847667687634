import React from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import { colors } from "../theme";

const PrivacyPolicy = () => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        width: "100%",
        minHeight: "100vh",
        backgroundColor: colors.background,
        color: colors.text.primary,
        pt: 8,
        pb: 20,
      }}
    >
      <Container maxWidth="md">
        <Box sx={{ display: "flex", alignItems: "center", mb: 4 }}>
          <Button
            startIcon={<ArrowBackIcon />}
            onClick={() => navigate("/")}
            sx={{
              color: "rgba(255,255,255,0.7)",
              "&:hover": {
                color: "rgba(255,255,255,1)",
                backgroundColor: "rgba(255,255,255,0.1)",
              },
              mr: 2,
            }}
          >
            Back to Home
          </Button>
        </Box>

        <Typography variant="h2" component="h1" gutterBottom sx={{ mb: 4 }}>
          Privacy Policy
        </Typography>

        <Typography
          variant="h5"
          component="h2"
          gutterBottom
          sx={{ mt: 4, mb: 2 }}
        >
          Last Updated: March 30, 2025
        </Typography>

        <Typography paragraph>
          Thank you for using Gazz.io. This Privacy Policy explains how we
          collect, use, and disclose information about you when you use our
          website and services.
        </Typography>

        <Typography
          variant="h5"
          component="h2"
          gutterBottom
          sx={{ mt: 4, mb: 2 }}
        >
          Information We Collect
        </Typography>

        <Typography paragraph>
          <strong>Personal Information:</strong> When you register for our
          service, we collect information that can be used to identify you, such
          as your name, email address, and other information you provide.
        </Typography>

        <Typography paragraph>
          <strong>Usage Information:</strong> We automatically collect
          information about your interactions with our service, including your
          IP address, browser type, pages viewed, and time spent on those pages.
        </Typography>

        <Typography
          variant="h5"
          component="h2"
          gutterBottom
          sx={{ mt: 4, mb: 2 }}
        >
          How We Use Your Information
        </Typography>

        <Typography paragraph>We use the information we collect to:</Typography>

        <ul>
          <Typography component="li" paragraph>
            Provide, maintain, and improve our services
          </Typography>
          <Typography component="li" paragraph>
            Process and complete transactions
          </Typography>
          <Typography component="li" paragraph>
            Send you technical notices, updates, and support messages
          </Typography>
          <Typography component="li" paragraph>
            Respond to your comments, questions, and requests
          </Typography>
          <Typography component="li" paragraph>
            Monitor and analyze trends, usage, and activities in connection with
            our services
          </Typography>
        </ul>

        <Typography
          variant="h5"
          component="h2"
          gutterBottom
          sx={{ mt: 4, mb: 2 }}
        >
          Information Sharing and Disclosure
        </Typography>

        <Typography paragraph>
          We do not share, sell, or rent your personal information to third
          parties for their marketing purposes without your explicit consent.
        </Typography>

        <Typography paragraph>We may share your information:</Typography>

        <ul>
          <Typography component="li" paragraph>
            With third-party service providers who perform services on our
            behalf
          </Typography>
          <Typography component="li" paragraph>
            In response to a request for information if we believe disclosure is
            in accordance with applicable law
          </Typography>
          <Typography component="li" paragraph>
            If we believe your actions are inconsistent with our user agreements
            or policies
          </Typography>
        </ul>

        <Typography
          variant="h5"
          component="h2"
          gutterBottom
          sx={{ mt: 4, mb: 2 }}
        >
          Security
        </Typography>

        <Typography paragraph>
          We take reasonable measures to help protect information about you from
          loss, theft, misuse, and unauthorized access.
        </Typography>

        <Typography
          variant="h5"
          component="h2"
          gutterBottom
          sx={{ mt: 4, mb: 2 }}
        >
          Your Choices
        </Typography>

        <Typography paragraph>
          You can access, update, or delete your account information at any time
          by logging into your account or contacting us.
        </Typography>

        <Typography
          variant="h5"
          component="h2"
          gutterBottom
          sx={{ mt: 4, mb: 2 }}
        >
          Changes to this Privacy Policy
        </Typography>

        <Typography paragraph>
          We may change this Privacy Policy from time to time. If we make
          changes, we will notify you by revising the date at the top of the
          policy and, in some cases, provide you with additional notice.
        </Typography>

        <Typography
          variant="h5"
          component="h2"
          gutterBottom
          sx={{ mt: 4, mb: 2 }}
        >
          Google API Services User Data Policy
        </Typography>

        <Typography paragraph>
          Our application uses Google API Services. Our use and transfer of
          information received from Google APIs to any other app will adhere to{" "}
          <a
            href="https://developers.google.com/terms/api-services-user-data-policy"
            style={{ color: "#4F46E5", textDecoration: "underline" }}
          >
            Google API Services User Data Policy
          </a>
          , including the Limited Use requirements.
        </Typography>

        <Typography paragraph>
          <strong>Limited Use:</strong> Gazz.io's use of information received
          from Google APIs will adhere to the Google API Services User Data
          Policy, including the Limited Use requirements. This means that:
        </Typography>

        <ul>
          <Typography component="li" paragraph>
            We will only use access to read, write, modify, or control Google
            user data for the purposes described in our privacy policy and/or
            user-facing policies.
          </Typography>
          <Typography component="li" paragraph>
            We will not use Google user data for serving advertisements.
          </Typography>
          <Typography component="li" paragraph>
            We will not allow humans to read Google user data unless (1) we have
            obtained the user's affirmative agreement, (2) reading is necessary
            for security purposes, (3) it is necessary to comply with applicable
            law, or (4) our use is limited to internal operations and the data
            is aggregated and anonymized.
          </Typography>
        </ul>

        <Typography
          variant="h5"
          component="h2"
          gutterBottom
          sx={{ mt: 4, mb: 2 }}
        >
          Contact Us
        </Typography>

        <Typography paragraph>
          If you have any questions about this Privacy Policy, please contact us
          at privacy@gazz.io.
        </Typography>

        {/* Bottom spacer */}
        <Box sx={{ height: 100 }} />
      </Container>
    </Box>
  );
};

export default PrivacyPolicy;

import { createTheme } from "@mui/material";

// Theme colors
export const colors = {
  // Core colors
  primary: "#5731FF", // Purple - primary UI elements
  secondary: "#8087ff", // Neon pink - code elements

  // Background colors
  background: "#121212", // Main background
  paper: "#1e1e1e", // Card/paper background

  // Code element colors
  codeBackground: "#2d2d2d",
  codeBorder: "#444",

  // Text colors
  text: {
    primary: "#ffffff",
    secondary: "#aaaaaa",
  },
};

// Code element styles - used in DevConsole.css and inline styles
export const codeStyles = {
  color: colors.secondary,
  fontWeight: 500,
  textShadow: `0 0 5px rgba(255, 113, 206, 0.6)`,
  backgroundColor: colors.codeBackground,
  padding: "4px 8px",
  borderRadius: "4px",
  fontFamily: "Roboto Mono, monospace",
  fontSize: "0.85em",
  border: `1px solid ${colors.codeBorder}`,
};

// Create the dark theme
const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: colors.primary,
    },
    secondary: {
      main: colors.secondary,
    },
    background: {
      default: colors.background,
      paper: colors.paper,
    },
    text: {
      primary: colors.text.primary,
      secondary: colors.text.secondary,
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  components: {
    MuiIconButton: {
      styleOverrides: {
        colorPrimary: {
          color: colors.primary,
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: `0 4px 20px rgba(87, 49, 255, 0.25)`,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: colors.paper,
        },
      },
    },
    // Remove focus outlines from all Material-UI components
    MuiButtonBase: {
      styleOverrides: {
        root: {
          "&:focus": {
            outline: "none !important",
          },
          "&.Mui-focusVisible": {
            outline: "none !important",
          },
        },
      },
    },
    // Remove focus styles for all clickable components
    MuiButton: {
      styleOverrides: {
        root: {
          "&:focus": {
            outline: "none !important",
            boxShadow: "none !important",
          },
          "&.Mui-focusVisible": {
            outline: "none !important",
            boxShadow: "none !important",
          },
        },
      },
    },
    // Remove focus styles for inputs
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "&:focus, &.Mui-focused": {
            outline: "none !important",
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: colors.primary,
              borderWidth: "1px",
            },
          },
        },
        focused: {
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: colors.primary,
            borderWidth: "1px",
          },
        },
      },
    },
    // Remove focus styles for tabs
    MuiTabs: {
      styleOverrides: {
        root: {
          "& .MuiTab-root": {
            "&:focus": {
              outline: "none !important",
            },
            "&.Mui-focusVisible": {
              outline: "none !important",
            },
          },
        },
      },
    },
  },
});

export default darkTheme;

import React from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import Paper from "@mui/material/Paper";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import EmailIcon from "@mui/icons-material/Email";
import PersonIcon from "@mui/icons-material/Person";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import BadgeIcon from "@mui/icons-material/Badge";
import { colors } from "../theme";

import Highlight from "../components/Highlight";
import Loading from "../components/Loading";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { useDbUser } from "../utils/useDbUser";
import { useMixpanel } from "../contexts/MixpanelContext";

export const ProfileComponent = () => {
  const { user } = useAuth0();
  const { dbUser, isLoading: isDbUserLoading, isDbAdmin } = useDbUser();
  const mixpanel = useMixpanel();

  if (isDbUserLoading) {
    return <Loading />;
  }

  const isAdmin = isDbAdmin();
  const createdDate = dbUser?.createdAt
    ? new Date(dbUser.createdAt).toLocaleDateString("en-US", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      })
    : "N/A";

  return (
    <Container sx={{ mb: 5, mt: 3 }}>
      <Card
        elevation={2}
        sx={{
          mb: 4,
          backgroundColor: `${colors.primary}10`,
          borderRadius: 2,
        }}
      >
        <CardHeader
          avatar={
            <Avatar
              src={user.picture}
              alt={user.name}
              sx={{
                width: 80,
                height: 80,
                border: isAdmin ? `3px solid ${colors.primary}` : "none",
              }}
            />
          }
          title={
            <Typography variant="h4" component="h1" gutterBottom>
              {user.name}
            </Typography>
          }
          sx={{
            pb: 0,
            "& .MuiCardHeader-avatar": {
              mr: 3,
            },
            "& .MuiCardHeader-content": {
              overflow: "visible",
            },
          }}
        />
        <CardContent>
          <Typography variant="h6" sx={{ mt: 2 }}>
            Welcome to Your Profile
          </Typography>
          <Typography variant="body1" paragraph>
            This is your personal profile page where you can view your account
            information.
          </Typography>
          <Divider sx={{ my: 2 }} />

          <Box
            sx={{
              bgcolor: `${colors.primary}15`,
              borderRadius: 2,
              p: 2,
              color: colors.text.primary,
            }}
          >
            <List sx={{ "& .MuiListItem-root": { py: 1.5 } }}>
              <ListItem>
                <ListItemIcon>
                  <PersonIcon sx={{ color: colors.primary }} />
                </ListItemIcon>
                <ListItemText
                  primary="Full Name"
                  secondary={user.name || "Not provided"}
                  primaryTypographyProps={{ fontWeight: 500 }}
                />
              </ListItem>

              <ListItem>
                <ListItemIcon>
                  <EmailIcon sx={{ color: colors.primary }} />
                </ListItemIcon>
                <ListItemText
                  primary="Email Address"
                  secondary={user.email}
                  primaryTypographyProps={{ fontWeight: 500 }}
                />
              </ListItem>

              <ListItem>
                <ListItemIcon>
                  <BadgeIcon sx={{ color: colors.primary }} />
                </ListItemIcon>
                <ListItemText
                  primary="Account Type"
                  secondary={
                    <Typography
                      variant="body2"
                      component="span"
                      sx={{
                        color: isAdmin ? colors.secondary : "text.secondary",
                        fontWeight: isAdmin ? 600 : 400,
                      }}
                    >
                      {isAdmin ? "Administrator" : "Standard User"}
                      {isAdmin && " ✅"}
                    </Typography>
                  }
                  primaryTypographyProps={{ fontWeight: 500 }}
                />
              </ListItem>

              <ListItem>
                <ListItemIcon>
                  <CalendarTodayIcon sx={{ color: colors.primary }} />
                </ListItemIcon>
                <ListItemText
                  primary="Account Created"
                  secondary={createdDate}
                  primaryTypographyProps={{ fontWeight: 500 }}
                />
              </ListItem>
            </List>
          </Box>
        </CardContent>
      </Card>

      {isAdmin && dbUser && (
        <Box sx={{ mt: 4, mb: 4 }}>
          <Typography
            variant="h5"
            component="h3"
            sx={{ mb: 2, color: colors.text.primary }}
          >
            Database User Profile
          </Typography>
          <Paper
            elevation={3}
            sx={{
              backgroundColor: colors.paper,
              borderRadius: 2,
              overflow: "hidden",
            }}
          >
            <Highlight>{JSON.stringify(dbUser, null, 2)}</Highlight>
          </Paper>
        </Box>
      )}

      {isAdmin && (
        <Box sx={{ mt: 4 }}>
          <Typography
            variant="h5"
            component="h3"
            sx={{ mb: 2, color: colors.text.primary }}
          >
            Auth0 User Profile
          </Typography>
          <Paper
            elevation={3}
            sx={{
              backgroundColor: colors.paper,
              borderRadius: 2,
              overflow: "hidden",
            }}
            className="mp-no-record"
          >
            <Highlight>{JSON.stringify(user, null, 2)}</Highlight>
          </Paper>
        </Box>
      )}
    </Container>
  );
};

export default withAuthenticationRequired(ProfileComponent, {
  onRedirecting: () => <Loading />,
});

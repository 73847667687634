import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Skeleton from "@mui/material/Skeleton";
import { colors } from "../theme";
import { useDbUser } from "../utils/useDbUser";

const Dashboard = () => {
  const { user, isAuthenticated } = useAuth0();
  const { dbUser, isLoading: isDbUserLoading } = useDbUser();

  // Check if user has admin access based on database role
  const hasAdminAccess = isAuthenticated && dbUser && dbUser.role === "admin";

  // Determine if we're in a loading state
  const isLoading = isAuthenticated && isDbUserLoading;

  // Render loading skeletons while waiting for database user data
  if (isLoading) {
    return (
      <Box sx={{ padding: 4, paddingTop: 0, maxWidth: 1200, margin: "0 auto" }}>
        <Typography variant="h4" component="h1" gutterBottom sx={{ mb: 2 }}>
          Dashboard
        </Typography>

        <Skeleton
          variant="rectangular"
          height={100}
          sx={{
            mb: 4,
            borderRadius: 1,
            backgroundColor: `${colors.primary}10`,
          }}
        />

        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Skeleton
              variant="rectangular"
              height={250}
              sx={{
                borderRadius: 1,
                transform: "none",
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Skeleton
              variant="rectangular"
              height={250}
              sx={{
                borderRadius: 1,
                transform: "none",
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <Skeleton
              variant="rectangular"
              height={180}
              sx={{
                mt: 2,
                borderRadius: 1,
                transform: "none",
              }}
            />
          </Grid>
        </Grid>
      </Box>
    );
  }

  return (
    <Box sx={{ padding: 4, paddingTop: 0, maxWidth: 1200, margin: "0 auto" }}>
      <Typography variant="h4" component="h1" gutterBottom sx={{ mb: 2 }}>
        Dashboard
      </Typography>

      {isAuthenticated && (
        <Paper
          elevation={2}
          sx={{
            p: 3,
            mb: 4,
            backgroundColor: `${colors.primary}10`,
            borderLeft: `4px solid ${colors.primary}`,
          }}
        >
          <Typography variant="h6" sx={{ mb: 1 }}>
            Welcome back, {user.name || user.email}!
          </Typography>
          <Typography variant="body1" color="text.secondary">
            You're logged in and have access to your personalized dashboard.
            {hasAdminAccess && " You have administrator privileges."}
          </Typography>
        </Paper>
      )}

      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Card
            elevation={3}
            sx={{
              height: "100%",
              transition:
                "transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out",
              "&:hover": {
                transform: "translateY(-5px)",
                boxShadow: `0 12px 20px ${colors.primary}20`,
              },
            }}
          >
            <CardContent sx={{ p: 3 }}>
              <Typography variant="h6" gutterBottom>
                Project Overview
              </Typography>
              <Typography variant="body1" sx={{ mb: 2 }}>
                This dashboard provides access to the main features of the
                application. From here, you can manage your settings, view
                analytics, and access other tools.
              </Typography>
              <Box
                sx={{
                  mt: 3,
                  display: "flex",
                  flexWrap: "wrap",
                  gap: 2,
                }}
              >
                {hasAdminAccess && (
                  <>
                    <Button
                      variant="outlined"
                      color="primary"
                      href="/dev-console"
                      sx={{
                        color: colors.text.primary,
                        borderColor: colors.primary,
                        "&:hover": {
                          color: colors.text.primary,
                          borderColor: colors.secondary,
                          backgroundColor: `${colors.primary}20`,
                        },
                      }}
                    >
                      Dev Console
                    </Button>
                    <Button
                      variant="outlined"
                      color="primary"
                      href="/mongodb-viewer"
                      sx={{
                        color: colors.text.primary,
                        borderColor: colors.primary,
                        "&:hover": {
                          color: colors.text.primary,
                          borderColor: colors.secondary,
                          backgroundColor: `${colors.primary}20`,
                        },
                      }}
                    >
                      MongoDB Viewer
                    </Button>
                  </>
                )}
                <Button
                  variant="outlined"
                  color="primary"
                  href="/profile"
                  sx={{
                    color: colors.text.primary,
                    borderColor: colors.primary,
                    "&:hover": {
                      color: colors.text.primary,
                      borderColor: colors.secondary,
                      backgroundColor: `${colors.primary}20`,
                    },
                  }}
                >
                  Profile
                </Button>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={6}>
          <Card
            elevation={3}
            sx={{
              height: "100%",
              transition:
                "transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out",
              "&:hover": {
                transform: "translateY(-5px)",
                boxShadow: `0 12px 20px ${colors.primary}20`,
              },
            }}
          >
            <CardContent sx={{ p: 3 }}>
              <Typography variant="h6" gutterBottom>
                Quick Start
              </Typography>
              <Typography variant="body1" paragraph>
                Get started with the platform by exploring these key areas:
              </Typography>
              <Box component="ul" sx={{ pl: 2 }}>
                {hasAdminAccess && (
                  <>
                    <Typography component="li" sx={{ mb: 1 }}>
                      <strong>Dev Console:</strong> Access development tools and
                      APIs.
                    </Typography>
                    <Typography component="li" sx={{ mb: 1 }}>
                      <strong>MongoDB Viewer:</strong> Browse and manage
                      database collections.
                    </Typography>
                  </>
                )}
                <Typography component="li" sx={{ mb: 1 }}>
                  <strong>Profile:</strong> Update your account settings and
                  preferences.
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card
            elevation={3}
            sx={{
              mt: 2,
              transition:
                "transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out",
              "&:hover": {
                transform: "translateY(-5px)",
                boxShadow: `0 12px 20px ${colors.primary}20`,
              },
            }}
          >
            <CardContent sx={{ p: 3 }}>
              <Typography variant="h6" gutterBottom>
                Getting Help
              </Typography>
              <Typography variant="body1">
                If you need assistance or have questions about the platform,
                please reach out to the support team. Documentation is available
                to help you make the most of the available features and tools.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                sx={{
                  mt: 3,
                  color: colors.text.primary,
                  "&:hover": {
                    color: colors.text.primary,
                    backgroundColor: colors.secondary,
                  },
                }}
                href="mailto:adriangazz@gmail.com"
              >
                Contact Support
              </Button>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Dashboard;

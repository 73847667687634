import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import { colors } from "../theme";

const Footer = () => (
  <Box
    component="footer"
    sx={{
      backgroundColor: colors.paper,
      padding: "12px 0",
      textAlign: "center",
      color: colors.text.primary,
    }}
  >
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        gap: 4,
      }}
    >
      <Typography
        component={Link}
        to="/privacy-policy"
        sx={{
          color: colors.text.secondary,
          textDecoration: "none",
          fontSize: "0.875rem",
          "&:hover": {
            color: colors.text.primary,
            textDecoration: "underline",
          },
        }}
      >
        Privacy Policy
      </Typography>
      <Typography
        component={Link}
        to="/terms-of-service"
        sx={{
          color: colors.text.secondary,
          textDecoration: "none",
          fontSize: "0.875rem",
          "&:hover": {
            color: colors.text.primary,
            textDecoration: "underline",
          },
        }}
      >
        Terms of Service
      </Typography>
    </Box>
  </Box>
);

export default Footer;
